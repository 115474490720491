import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths, CrefoCompany } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import Button from '../../components/button/button';
import { ReactComponent as ArrowRight } from '../../components/icons/chevron-right.svg';
import { ReactComponent as ArrowForwardSmallIcon } from '../../components/icons/forward-arrow-small.svg';
import noResult from '../../components/images/no-search-result.png';
import Modal from '../../components/modal/modal';
import RadioContainer from '../../components/radio-container/radio-container';
import Radio from '../../components/radio/radio';
import ButtonGroup from '../../molecules/button-group/button-group';
import './company-search-result.css';

const CompanySearchResult: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Sequence Store
  const { sequence } = useSelector((state: RootState) => state.sequence);

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Search Result State
  const [searchResult] = useState<CrefoCompany[]>(
    activeApplication?.company_search_result || [],
  );

  // Info modal control
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const [selectedCompanyValue, setSelectedCompanyValue] = useState<string>('');

  // Error modal control
  const [errorModalVisibility, setErrorModalVisibility] =
    useState<boolean>(false);

  // History Hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Navigation class
  const { nextRoute, goToStep } = new Navigation();

  // Go Back
  const goBack = () => {
    goToStep(0, 0);
    navigate(
      `/lease-application/${activeApplication?.uuid}/${
        sequence + 1
      }/company-profile/company-info`,
    );
  };

  // Go Next
  const goNext = () => navigate(nextRoute());

  // State company list result from api
  const [fetching, setFecthing] = useState<boolean>(false);

  // On form submit
  const submit = () => {
    setFecthing(true);
    const selectedCompany = searchResult.find(
      (company) => company.crefonummer === selectedCompanyValue,
    );
    const company = selectedCompany || searchResult[0];

    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          crefo_data: {
            id: company.crefonummer,
            name: company.name,
            address: {
              street_line: company.strasseHausnummer,
              postal_code: company.plz,
              locality: company.ort,
              country_code: 'DEU',
            },
          },
        },
        path: APIPaths.CREFO_DATA,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setFecthing(false);
        navigate(nextRoute());
      })
      .catch(() => {
        setErrorModalVisibility(true);
        setFecthing(false);
      });
  };

  if (searchResult.length > 0) {
    return (
      <Layout stepStatus heading={getText('company_search_results_title')}>
        <h2 className='content-bold top-72'>
          Bitte wähle dein Unternehmen aus
        </h2>
        <div className='search-result-content top-36'>
          <RadioContainer twoLines>
            {searchResult.map((company, index) => (
              <Radio
                twolines
                checked={selectedCompanyValue === company.crefonummer}
                onChange={() => {
                  setSelectedCompanyValue(company.crefonummer);
                }}
                key={index}
              >
                <div className='search-result'>
                  <p className='content-bold'>{company.name}</p>
                  <p className='content-normal dark-grey'>
                    {company.strasseHausnummer} - {company.plz}&nbsp;
                    {company.ort}
                  </p>
                </div>
              </Radio>
            ))}
          </RadioContainer>
          <div
            className='no-match top-24'
            role='presentation'
            onClick={() => setModalVisibility(true)}
          >
            <ArrowForwardSmallIcon />
            <span className='button-text'>
              Mein Unternehmen ist nicht dabei
            </span>
          </div>
        </div>

        <Button
          loading={fetching}
          disabled={!selectedCompanyValue}
          type='submit'
          theme='primary'
          className='float-right long top-48'
          onClick={() => submit()}
        >
          weiter
        </Button>

        <Modal
          type='middle'
          direction='down'
          onClose={() => setModalVisibility(false)}
          isOpen={modalVisibility}
        >
          <h1 className='heading'>{getText('would')}</h1>

          <ul className='arrow-list'>
            <li className='arrow-list-item'>
              <ArrowRight className='arrow-list-icon' />
              <p className='content-normal dark-grey'>
                Sie haben angegeben, dass Ihr Unternehmen nicht dabei ist. Was
                möchten Sie tun?
              </p>
            </li>
          </ul>

          <ButtonGroup
            type='default'
            className='top-48'
            buttonOneProps={{
              theme: 'secondary',
              className: 'content-normal',
              children: 'Unternehmensdaten manuell eingeben',
              type: 'button',
              onClick: () => goNext(),
            }}
            buttonTwoProps={{
              theme: 'primary',
              className: 'content-normal',
              children: 'Erneut suchen',
              type: 'button',
              onClick: () => goBack(),
            }}
          />
        </Modal>
        <Modal
          isOpen={errorModalVisibility}
          onClose={() => setErrorModalVisibility(false)}
          type='middle'
          direction='down'
        >
          <div>
            <h1 className='heading b2c'>Bitte beachte:</h1>
            <p className='content-normal'>
              Die Rechtsform, die Du zuvor gewählt hast, wurde auf der Grundlage
              der Wahl des Unternehmens aus der vorherigen Liste angepasst.
              <br />
              <br />
              Wenn diese nicht korrekt ist, klicke bitte auf zurück und
              korrigiere sie.
            </p>

            <Button
              type='submit'
              theme='secondary'
              className='long top-24 margin-auto'
              onClick={() => setErrorModalVisibility(false)}
            >
              Verstanden
            </Button>
          </div>
        </Modal>
      </Layout>
    );
  }

  return (
    <Layout heading={getText('no_company_found')} stepStatus>
      <div className='result'>
        <div>
          <img src={noResult} alt='vehicle' />
        </div>
        <div>
          <span className='content-normal dark-grey'>
            {getText('no_company_found_desc1')}
          </span>
          <span className='result-title content-bold'>
            {getText('no_company_found_desc2')}
          </span>
          <span className='content-normal dark-grey'>
            {getText('no_company_found_desc3')}
          </span>
          <Button
            type='submit'
            theme='secondary'
            className='float-right full top-96'
            onClick={() => goNext()}
          >
            Daten manuell eingeben
          </Button>
          <Button
            type='submit'
            theme='primary'
            className='float-right full top-16'
            onClick={() => goBack()}
          >
            Erneut suchen
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CompanySearchResult;
