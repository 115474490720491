import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../redux/types';
import Navigation from '../../../../utils/navigation';
import './confirm-section.css';

interface ConfirmElements {
  title: string | undefined;
  value: string | React.ReactElement | undefined;
  onClick?: () => void;
  titleClassName?: string;
  valueClassName?: string;
  condition?: boolean;
}

interface ConfirmSectionProps {
  style?: CSSProperties;
  sectionTitle: string;
  step?: number;
  editSection?: () => void;
  elements: ConfirmElements[] | undefined;
  hasEditButton?: boolean;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({
  sectionTitle,
  step,
  editSection,
  elements,
  style,
  hasEditButton = true,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // sequence Storage
  const { device } = useSelector((state: RootState) => state.sequence);

  const navigate = useNavigate();

  const { goToStep } = new Navigation();

  const linkTo = (targetStep: number) => {
    if (targetStep !== -1) {
      navigate(goToStep(targetStep, 0, 0, activeApplication as any));
    }
  };

  return (
    <div className='confirm-section top-16' style={style}>
      {device === 'desktop' && (
        <h2 className='small-text-bold confirm-desktop'>{sectionTitle}</h2>
      )}
      {device === 'mobile' && (
        <div className='confirm-only-mobile'>
          <h2 className='small-text-bold'>{sectionTitle}</h2>
          {hasEditButton && (
            <div className='title-b2c-icon'>
              <span
                data-testid='edit'
                role='presentation'
                onClick={() => {
                  if (step !== undefined) linkTo(step);
                  if (editSection) editSection();
                }}
                className='small-text icon-text link'
              >
                ändern
              </span>
            </div>
          )}
        </div>
      )}
      <div className='confirm-element-section'>
        <ul className='confirm-elements'>
          {elements?.map(
            (
              {
                title,
                value,
                condition = true,
                onClick,
                titleClassName = 'small-text dark-grey',
                valueClassName = 'small-text',
              },
              index,
            ) => (
              <li
                className='confirm-element'
                data-testid={title?.toLocaleLowerCase()}
                key={`${title}_${index}`}
              >
                {condition && (
                  <>
                    {title && (
                      <h4
                        style={{ width: '216px' }}
                        className={`${titleClassName} dark-grey`}
                      >
                        {title}
                      </h4>
                    )}
                    {value && (
                      <p
                        role='presentation'
                        className={`confirm-value ${valueClassName} ${
                          onClick ? 'link' : ''
                        }`}
                        onClick={onClick}
                      >
                        {value}
                      </p>
                    )}
                  </>
                )}
              </li>
            ),
          )}
        </ul>
      </div>
      {device === 'desktop' && hasEditButton && (
        <div className='title-b2c-icon confirm-desktop'>
          <span
            data-testid='edit'
            role='presentation'
            onClick={() => {
              if (step !== undefined) linkTo(step);
              if (editSection) editSection();
            }}
            className='small-text icon-text link'
          >
            ändern
          </span>
        </div>
      )}
    </div>
  );
};

export default ConfirmSection;
