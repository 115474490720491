import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { setActiveLeaseAppDetails } from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import routeDefs from '../../../sequence/lease-app-routes';
import LeaseAppStatusOverview from '../status-overview/status-overview';

const LeaseApplication = () => {
  const { id } = useParams();
  const { accessToken } = useSelector((rootState: RootState) => rootState.auth);
  const { sequence } = useSelector(
    (rootState: RootState) => rootState.sequence,
  );
  const dispatch = useAppDispatch();
  const { activeApplication, leaseApplications } = useSelector(
    (rootState: RootState) => rootState.leaseApp,
  );

  useEffect(() => {
    if (
      leaseApplications &&
      leaseApplications.length > 0 &&
      id !== activeApplication?.uuid
    ) {
      dispatch(setActiveLeaseAppDetails({ accessToken, leaseAppId: id || '' }));
    }
  }, [id, leaseApplications, activeApplication, accessToken, dispatch]);

  return (
    <Routes>
      <Route path={`/${sequence + 1}`} element={<LeaseAppStatusOverview />} />

      {routeDefs().map((route) => (
        <Route
          key={route.route(id || '', (sequence + 1).toString())}
          path={`${
            route.route(id || '', (sequence + 1).toString()).split('/')[3]
          }/${route.route(id || '', (sequence + 1).toString()).split('/')[4]}/${
            route.route(id || '', (sequence + 1).toString()).split('/')[5]
              ? route.route(id || '', (sequence + 1).toString()).split('/')[5]
              : ''
          }`}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

export default LeaseApplication;
