import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isAuthenticated from '../../../redux/auth/authUtils';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { clearTimer } from '../../../utils/helper';
import Button from '../../b2b/components/button/button';
import ResetPassword from '../../common/images/reset-password.jpg';

const Verified: React.FC = () => {
  // LeaseApplication Storage
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  const { accessToken } = useSelector((rootState: RootState) => rootState.auth);

  // Auth Storage
  const auth = useSelector((state: RootState) => state.auth);

  // History hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // If User Authenticated
  const isUserAuthenticated = isAuthenticated(auth);

  // Automatic redirect after 4 sec
  useEffect(() => {
    const navigationTimer = setTimeout(() => {
      isUserAuthenticated
        ? navigate(`/lease-application/${leaseApplications[0]?.uuid}/`)
        : navigate(`/login`);
    }, 28500);

    return () => {
      clearTimer(navigationTimer);
    };
  }, [accessToken, isUserAuthenticated, leaseApplications, dispatch, navigate]);

  // Handle button click
  const handleClick = () => {
    if (isUserAuthenticated) {
      navigate(`/lease-application/${leaseApplications[0].uuid}/`);
    } else {
      navigate(`/login`);
    }
  };

  return (
    <div className='auth-container'>
      <div className='auth-form-container login'>
        <div className='auth-form-content login'>
          <h1 className='title'>Deine Registrierung war erfolgreich.</h1>
          <p className='content-normal dark-grey'>
            Deine Registrierung war erfolgreich! Du kannst jetzt mit deinem
            Antrag fortfahren.
          </p>
          <p className='top-48'>
            <span className='content-bold'>
              Herzlich willkommen bei der ALD Automotive.
            </span>
          </p>
          <p className='content-normal dark-grey top-48'>
            Du wirst in Kürze automatisch auf unsere Übersichtsseite
            weitergeleitet. Alternativ kannst du auch auf den Button „
            {isUserAuthenticated ? 'Antrag starten' : 'Anmelden'}“ klicken, um
            direkt fortzufahren.
          </p>

          <Button
            type='submit'
            theme='primary'
            className='long top-48'
            onClick={handleClick}
          >
            {isUserAuthenticated ? 'Antrag starten' : 'Anmelden'}
          </Button>
        </div>
      </div>
      <div className='auth-image-container login'>
        <div
          className='container-background'
          style={{ backgroundImage: `url(${ResetPassword})` }}
        />
      </div>
    </div>
  );
};

export default Verified;
