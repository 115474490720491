import * as yup from 'yup';
import config from '../config';
import { Cache } from '../redux/cache-slice';
import { ValidateDocuments } from '../redux/leaseApp/types';
import {
  AuthorizedRepresentative,
  IdentityDocumentType,
  LeaseApplication,
  LeasingAdditionalCost,
  LegalForm,
  Signer,
  SignerState,
} from '../types/instant-lease-api';

export const validateCompanyData = (
  leaseApp: LeaseApplication | undefined | null,
): boolean => {
  if (leaseApp) {
    const schema = yup.object().shape({
      legal_form: yup.string().required(),
      name: yup.string().required(),
      main_industry: yup.string().required(),
      establishment_date: yup.string().required(),
      website_url: yup.string(),
      car_pool_size: yup.number(),
      bank_account: yup.object().shape({
        iban: yup.string().required(),
        bic: yup.string().required(),
        bank_name: yup.string().required(),
      }),
      contact_details: yup.object().shape({
        emails: yup
          .array()
          .of(
            yup.object().shape({
              value: yup.string().required(),
              purpose: yup.string().required(),
            }),
          )
          .min(1)
          .required(),
        phone_numbers: yup
          .array()
          .of(
            yup.object().shape({
              value: yup.string().required(),
              purpose: yup.string().required(),
            }),
          )
          .min(1)
          .required(),
        addresses: yup
          .array()
          .of(
            yup.object().shape({
              additional_lines: yup.array().of(yup.string()),
              locality: yup.string().required(),
              postal_code: yup.string().required(),
              street_line: yup.string().required(),
            }),
          )
          .min(1)
          .required(),
      }),
    });

    if (leaseApp.customer?.company) {
      return schema.isValidSync(leaseApp.customer.company);
    }
    schema.validate(leaseApp.customer?.individual).catch((e: Error) => {
      if (config.ENV === 'local') {
        console.error('Validation Error', e);
      }
    });

    return schema.isValidSync(leaseApp.customer?.individual);
  }

  return false;
};

export const validatePersonalData = (
  leaseApp: LeaseApplication | undefined | null,
): boolean => {
  if (leaseApp) {
    const schema = yup.object().shape({
      person: yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        gender: yup.string().required(),
        date_of_birth: yup.string().required(),
        birth_place: yup.string().required(),
        contact_details: yup.object().shape({
          phone_numbers: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                purpose: yup.string().required(),
                data_usage: yup.object().shape({
                  answer: yup.boolean().required(),
                }),
              }),
            )
            .min(1)
            .required(),
          addresses: yup
            .array()
            .of(
              yup.object().shape({
                additional_lines: yup.array().of(yup.string()),
                locality: yup.string().required(),
                postal_code: yup.string().required(),
                street_line: yup.string().required(),
              }),
            )
            .min(1)
            .required(),
        }),
      }),

      bank_account: yup.object().shape({
        iban: yup.string().required(),
        bic: yup.string().required(),
        bank_name: yup.string().required(),
      }),
    });

    if (leaseApp.customer?.private) {
      return schema.isValidSync(leaseApp.customer.private);
    }
    schema.validate(leaseApp.customer?.private).catch((e: Error) => {
      if (config.ENV === 'local') {
        console.error('Validation Error', e);
      }
    });

    return schema.isValidSync(leaseApp.customer?.private);
  }

  return false;
};

export const validateDocuments = (
  activeLeaseApp: LeaseApplication | null,
  cache?: Cache | null,
): ValidateDocuments => {
  let result: ValidateDocuments;

  if (activeLeaseApp) {
    // Get uploaded documents
    const uploadedDocuments = activeLeaseApp.registered_documents;

    // Customer of the company
    const customer =
      activeLeaseApp?.customer?.company || activeLeaseApp?.customer?.individual;

    //  Legal Form of the company
    const legalForm =
      activeLeaseApp?.customer?.company?.legal_form ||
      activeLeaseApp?.customer?.individual?.legal_form;

    // If legalForm and uploaded Documents exist
    if (legalForm) {
      const ID = uploadedDocuments?.find(
        (document) => document.document_type === 'PROOF_OF_RESIDENCE',
      );

      // const TR = uploadedDocuments?.find(
      //   (document) => document.document_type === "TRADE_REGISTRY_RECORD"
      // );

      const TRR = uploadedDocuments?.find(
        (document) => document.document_type === 'TRANSPARENCY_REGISTRY_RECORD',
      );

      const ST = uploadedDocuments?.find(
        (document) => document.document_type === 'FOUNDATION_REGISTRY_RECORD',
      );

      const PA = uploadedDocuments?.find(
        (document) => document.document_type === 'PROOF_OF_COMMERCIAL_ACTIVITY',
      );

      const LOA = uploadedDocuments?.find(
        (document) => document.document_type === 'LETTER_OF_ATTORNEY',
      );

      const LOAID = uploadedDocuments?.find(
        (document) => document.document_type === 'LETTER_OF_ATTORNEY_SIGNER_ID',
      );

      const PAA = uploadedDocuments?.find(
        (document) => document.document_type === 'PARTNERSHIP_AGREEMENT',
      );

      const GENORE = uploadedDocuments?.find(
        (document) => document.document_type === 'COOPERATIVE_REGISTRY_RECORD',
      );

      const VERE = uploadedDocuments?.find(
        (document) => document.document_type === 'ASSOCIATION_REGISTRY_RECORD',
      );

      switch (legalForm) {
        case LegalForm.STIFTUNG:
          result = {
            requiredDocuments: [
              {
                enum: 'FOUNDATION_REGISTRY_RECORD',
                name: 'Stiftungregister',
              },

              {
                enum: 'TRANSPARENCY_REGISTRY_RECORD',
                name: 'Transparenzregister',
              },
            ],
            exist: !!(ST && TRR),
          };
          break;
        case LegalForm.GEWERBEBETRIEB:
          result = {
            requiredDocuments: [
              {
                enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
                name: 'Nachweis gewerblicher Tätigkeit',
              },
            ],
            exist: !!PA,
          };
          break;
        case LegalForm.FREIE_BERUFE:
          result = {
            requiredDocuments: [
              {
                enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
                name: 'Nachweis gewerblicher Tätigkeit',
              },
            ],
            exist: !!PA,
          };
          break;
        case LegalForm.PARTNERSCHAFTSGESELLSCHAFT:
          result = {
            requiredDocuments: [
              {
                enum: 'PARTNERSHIP_AGREEMENT',
                name: 'Partnerschatfsregisterauszug',
              },
              {
                enum: 'TRANSPARENCY_REGISTRY_RECORD',
                name: 'Transparenzregister',
              },
            ],
            exist: !!(PAA && TRR),
          };
          break;
        case LegalForm.EINGETRAGENE_GENOSSENSCHAFT:
          result = {
            requiredDocuments: [
              {
                enum: 'COOPERATIVE_REGISTRY_RECORD',
                name: 'Genossenschaftsregister',
              },
            ],
            exist: !!GENORE,
          };
          break;
        case LegalForm.EINGETRAGENER_VEREIN:
          result = {
            requiredDocuments: [
              {
                enum: 'ASSOCIATION_REGISTRY_RECORD',
                name: 'Vereinsregistereintrag',
              },
            ],
            exist: !!VERE,
          };
          break;
        case LegalForm.BUND_LAENDER_BEHOERDEN:
          result = {
            requiredDocuments: [
              {
                enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
                name: 'Satzung oder Organigramm, aus dem die Vertretungsregelung hervorgeht',
              },
            ],
            exist: !!PA,
          };
          break;
        case LegalForm.SONSTIGE_RECHTSFORM:
          result = {
            requiredDocuments: [
              {
                enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
                name: 'Unternehmensnachweis oder Nachweis der Vertretungsreglung',
              },
            ],
            exist: !!PA,
          };
          break;
        case LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS:
          result = {
            requiredDocuments: [
              {
                enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
                name: 'Gesellschaftsvertrag',
              },
            ],
            exist: !!PA,
          };
          break;
        default:
          result = {
            requiredDocuments: [],
            exist: true,
          };
          break;
      }

      if (
        (!customer?.trade_registry_number || !customer?.local_court) &&
        legalForm === LegalForm.EINGETRAGENER_KAUFMANN
      ) {
        result = {
          requiredDocuments: [
            {
              enum: 'PROOF_OF_COMMERCIAL_ACTIVITY',
              name: 'Gewerbeanmeldung',
            },
            ...result.requiredDocuments,
          ],
          exist: !!PA,
        };
      }

      if (
        legalForm === LegalForm.GMBH_CO_KG ||
        legalForm === LegalForm.GMBH_CO_KG_AA ||
        legalForm === LegalForm.AG_CO_KG
      ) {
        result = {
          requiredDocuments: [
            {
              enum: 'TRANSPARENCY_REGISTRY_RECORD',
              name: 'Transparenzregister',
            },
            ...result.requiredDocuments,
          ],
          exist: !!(result.exist && TRR),
        };
      }

      if (cache?.hasPassport) {
        result = {
          requiredDocuments: result.requiredDocuments,
          exist: !!(result.exist && ID),
        };
      }

      if (cache?.hasTransparencyRegister) {
        result = {
          requiredDocuments: result.requiredDocuments,
          exist: !!(result.exist && TRR),
        };
      }

      if (cache?.hasLetterOfAuthority) {
        result = {
          requiredDocuments: result.requiredDocuments,
          exist: !!(result.exist && LOA && LOAID),
        };
      }

      return result;
    }
  }

  result = {
    requiredDocuments: [],
    exist: false,
  };

  return result;
};

export const validateSignerData = (
  signers: AuthorizedRepresentative[] | Signer[] | undefined | null,
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  let hasValidDocument = true;
  if (signers && activeLeaseApp) {
    const schema = yup.object().shape({
      person: yup.object().shape({
        uuid: yup.string().required(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        date_of_birth: yup.string().required(),
        citizenship: yup.string().required(),
        birth_place: yup.string().required(),
        contact_details: yup.object().shape({
          emails: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                purpose: yup.string().required(),
              }),
            )
            .min(1)
            .required(),
          phone_numbers: yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required(),
                purpose: yup.string().required(),
              }),
            )
            .min(1)
            .required(),
          addresses: yup
            .array()
            .of(
              yup.object().shape({
                street_line: yup.string().required(),
                additional_lines: yup.array().of(yup.string()),
                postal_code: yup.string().required(),
                locality: yup.string().required(),
              }),
            )
            .min(1)
            .required(),
        }),
        identity_documents: yup
          .array()
          .of(
            yup.object().shape({
              uuid: yup.string(),
              type: yup.string().required(),
              identity_number: yup.string(),
            }),
          )
          .min(1)
          .required(),
      }),
    });

    schema.validate(signers[0]).catch((err: Error) => {});

    const result = signers.map((signer) => {
      if (
        signer?.person?.identity_documents &&
        signer?.person?.identity_documents.length > 0 &&
        signer?.person?.identity_documents[0].type ===
          IdentityDocumentType.PASSPORT
      ) {
        const identityDocumentId =
          signer?.person?.identity_documents[0].document_id;

        const document = activeLeaseApp?.documents?.find(
          (doc) => doc.key === identityDocumentId,
        );

        hasValidDocument = !!document;
      }
      return schema.isValidSync(signer) && hasValidDocument;
    });

    return result.every((item) => item === true);
  }

  return false;
};

export const validateSignerState = (
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  const signers =
    activeLeaseApp?.customer?.individual?.signers ||
    activeLeaseApp?.customer?.company?.signers ||
    activeLeaseApp?.customer?.private?.signers;

  if (signers) {
    const termsState = signers.map(
      (signer) =>
        signer?.person?.signer_details?.action_state ===
        SignerState.ACTION_STATE_SIGNED,
    );
    if (termsState.includes(false)) {
      return false;
    }
    return true;
  }
  return false;
};

export const validateSignerTerms = (
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  const signers =
    activeLeaseApp?.customer?.individual?.signers ||
    activeLeaseApp?.customer?.company?.signers ||
    activeLeaseApp?.customer?.private?.signers;

  if (signers) {
    const termsState = signers.map(
      (signer) =>
        signer.person?.signer_details?.terms_and_conditions_confirmed === true,
    );

    if (termsState.includes(false)) {
      return false;
    }
    return true;
  }
  return false;
};

export const validateAdditionalCost = (
  additionalCost: LeasingAdditionalCost | undefined,
): boolean => {
  if (additionalCost) {
    const schema = yup.object().shape({
      freight: yup.number().positive().min(0),
      logistics: yup.number().positive().min(0),
      registration: yup.number().positive().min(0),
      returnFreight: yup.number().positive().min(0),
    });

    return schema.isValidSync(additionalCost);
  }
  return false;
};
