import {CapitalizeFirstLetter} from "../../../utils/helper";

export const getVehicleType = (vehicleType: string): string => {
  return vehicleType === 'USED' ? 'Gebrauchtwagen' : 'Neuwagen';
};

export const getFuelType = (fuelType: string): string => {
  switch (fuelType) {
    case 'ELECTRIC':
      return 'Elektro';
    case 'Hybrid':
      return 'Hybrid';
    case 'DIESEL':
      return 'Diesel';
    case 'PETROL':
      return 'Benzin';
    case 'MILD_HYBRID':
      return 'Mild-Hybrid';
    case 'PLUGIN_HYBRID':
      return 'Plug-in-Hybrid';
    case 'HYDROGEN':
      return 'Wasserstoff';
    case 'UNKNOWN':
      return 'Unbekannt';
    default:
      return fuelType;
  }
};
export const getVehicleBody = (vehicleBody: string): string => {
  switch (vehicleBody) {
    case 'CABRIO':
      return 'Cabrio';
    case 'CLOSED':
      return 'Geschlossen';
    case 'COUPE':
      return 'Coupé';
    case 'ROADSTER':
      return 'Roadster';
    case 'SEDAN':
      return 'Limousine';
    case 'SMALL_CAR':
      return 'Kleinwagen';
    case 'SPORTS_CAR':
      return 'Sportwagen';
    case 'SUV':
      return 'SUV/Geländewagen';
    case 'TRANSPORTER':
      return 'Transporter';
    case 'VAN':
      return 'Van/Minibus';
    case 'WAGON':
      return 'Kombi';
    case 'UNKNOWN':
      return 'Unbekannt';
    default:
      return CapitalizeFirstLetter((vehicleBody.toLowerCase().replace('_',' ')));
  }
};

export const getGearBoxType = (gearBoxType: string): string => {
  switch (gearBoxType) {
    case 'AUTOMATIC':
      return 'Automatik';
    case 'MANUAL':
      return 'Manuell';
    case 'UNKNOWN':
      return 'Unbekannt';
    default:
      return gearBoxType;
  }
};
