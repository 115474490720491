import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from '../auth/auth.guard';
import AuthService from '../auth/auth.service';
import PublicGuard from '../auth/public.guard';
import useApiInterceptors from '../hooks/useApiInterceptors';
import useBrowserButtonEffect from '../hooks/useBrowserButtonEffect';
import useCustomerServiceHook from '../hooks/useCustomerServiceHook';
import useDeviceBreakpoint from '../hooks/useDeviceBreakpoint';
import useInitialLoaderHook from '../hooks/useInitialLoaderHook';
import useMatomo from '../hooks/useMatomo';
import useOfferCreation from '../hooks/useOfferCreation';
import useThemeHook from '../hooks/useThemeHook';
import useUserInfo from '../hooks/useUserInfo';
import { RootState } from '../redux/types';
import { isAdmin } from '../utils/helper';
import Loader from '../view/b2b/components/loader/loader';
import Modal from '../view/b2b/components/modal/modal';
import ActiveAppsOverview from '../view/common/active-lease-applications/overview/overview';
import AGB from '../view/common/agb/agb';
import CreditCheckLoader from '../view/common/animation/credit-check-loader/credit-check-loader';
import LeaseApplicationLoader from '../view/common/animation/lease-application-loader/lease-application-loader';
import ForgetPassword from '../view/common/auth-pages/forget-password/forget-password';
import Register from '../view/common/auth-pages/register/register';
import ResetPassword from '../view/common/auth-pages/reset-password/reset-password';
import Bootstrap from '../view/common/bootstrap/bootstrap';
import Callback from '../view/common/callback/callback';
import ContractsHistory from '../view/common/contracts-history/contracts-history';
import DatenschutzKontodaten from '../view/common/datenschutz-kontodaten/datenschutz-kontodaten';
import ErrorBoundary from '../view/common/error-boundary/error-boundary';
import Footer from '../view/common/footer/footer';
import Header from '../view/common/header/header';
import Home from '../view/common/home/home';
import IDF from '../view/common/idf/idf';
import IdnowLoggedOut from '../view/common/idnow-response/idnow-loggedout/idnow-loggedout';
import Impressum from '../view/common/impressum/impressum';
import LeaseApplication from '../view/common/lease-application/lease-application';
import Logout from '../view/common/logout/logout';
import NoOffer from '../view/common/no-offer/no-offer';
import Notification from '../view/common/notification/notification';
import SummaryB2B from '../view/common/offer/summary-b2b';
import SummaryB2C from '../view/common/offer/summary-b2c';
import Profile from '../view/common/profile/profile';
import Verified from '../view/common/verified/verified';
import './app.css';

const App: React.FC = () => {
  // Hooks
  useDeviceBreakpoint();
  useMatomo();
  useBrowserButtonEffect();
  useUserInfo();
  useThemeHook();
  useCustomerServiceHook();
  useOfferCreation();
  useApiInterceptors();

  // Initial Animation Loader
  const { loadingAnimationVisibility } = useInitialLoaderHook();

  // Cache Store
  const {
    loaderModalVisible,
    loaderModalText,
    creditCheckModalVisibility,
    creditCheckModalAnimation,
  } = useSelector((state: RootState) => state.cache);

  return (
    <ErrorBoundary>
      <AuthService>
        <Header />
        <main id='main'>
          <Routes>
            <Route path='/*' element={<Navigate to='/start' replace />} />

            {isAdmin() && (
              <>
                <Route
                  path='/home'
                  element={<AuthGuard Component={<Home />} />}
                />
                <Route
                  path='/idf'
                  element={<AuthGuard Component={<IDF />} />}
                />
              </>
            )}

            <Route
              path='/logout'
              element={<AuthGuard Component={<Logout />} />}
            />

            <Route
              path='/contracts-history'
              element={<AuthGuard Component={<ContractsHistory />} />}
            />

            <Route
              path='/datenschutz-kontodaten'
              element={<DatenschutzKontodaten />}
            />

            <Route path='/impressum' element={<Impressum />} />

            <Route
              path='/reset-password'
              element={<PublicGuard Component={<ResetPassword />} />}
            />

            <Route
              path='/change-password/:token'
              element={<PublicGuard Component={<ForgetPassword />} />}
            />

            <Route path='/verified' element={<Verified />} />

            <Route path='/agb' element={<AGB />} />

            <Route
              path='/login'
              element={<PublicGuard Component={<Register />} />}
            />

            <Route
              path='/register/:offerId?'
              element={<PublicGuard Component={<Register />} />}
            />

            <Route path='/callback' element={<Callback />} />

            <Route path='/no-offer' element={<NoOffer />} />

            <Route path='/offer' element={<SummaryB2B />} />

            <Route path='/b2cOffer' element={<SummaryB2C />} />

            <Route
              path='/idnow/response/:status'
              element={<IdnowLoggedOut />}
            />

            <Route
              path='/active-applications/overview'
              element={<AuthGuard Component={<ActiveAppsOverview />} />}
            />

            <Route
              path='/profile'
              element={<AuthGuard Component={<Profile />} />}
            />

            <Route
              path={`/lease-application/:id/*`}
              element={<AuthGuard Component={<LeaseApplication />} />}
            />

            <Route
              path='/start/:publicationId?/:pricingId?/:offerId?'
              element={<PublicGuard Component={<Bootstrap />} />}
            />
          </Routes>
        </main>
        <Footer />
        <Notification />

        {(loaderModalVisible || loadingAnimationVisibility) && (
          <div className='loader-container'>
            <LeaseApplicationLoader animated={false} />
            <h2 className='content-heading'>
              {loaderModalText || 'Lade Fahrzeug- und Antragsdaten...'}
            </h2>
            <Loader />
          </div>
        )}

        <Modal
          isOpen={creditCheckModalVisibility}
          type='middle'
          withExitBtn={false}
          direction='none'
          background='default'
          hasInAnimated
        >
          <div
            className={`loader-container ${
              creditCheckModalAnimation === 'In' ? 'credit' : 'credit-out'
            } `}
          >
            <CreditCheckLoader />
            <h2 className='title'>Bonitätsprüfung gestartet</h2>
            <Loader />
          </div>
        </Modal>
      </AuthService>
    </ErrorBoundary>
  );
};

export default App;
