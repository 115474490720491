import { motion } from 'framer-motion';
import ls from 'localstorage-slim';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelLeaseApplication } from '../../../../api/instant-lease-api';
import { setLoaderModal } from '../../../../redux/cache-slice';
import {
  clearCarData,
  fetchLeaseApps,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { formatGermanPrice } from '../../../../utils/getter';
import CarImage from '../../../common/icons/hidden-car.svg';
import ButtonGroup from '../../molecules/button-group/button-group';
import './car-selection.css';

interface carSelectionProps {
  uuid: string;
  img?: string;
  carName?: string;
  color?: string;
  rate?: number | string;
  time?: string;
  cushion?: string;
  appLeaseDate?: string | Date | number | null;
}

const CarSelection: React.FC<carSelectionProps> = ({
  img,
  uuid,
  carName = '',
  appLeaseDate = '',
  time = '',
  color = '',
  rate = '',
  cushion = '',
}: carSelectionProps) => {
  // Store
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Sequence Store
  const { sequence } = useSelector((state: RootState) => state.sequence);

  // Access Token
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // History Hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // handle on click on leasing app
  const handleClick = (selectedUUID: string) => {
    const cancelApps = leaseApplications
      .filter((app) => app.uuid !== selectedUUID)
      .map((app) => app.uuid);
    const promises = cancelApps.map((leaseApp) => {
      return cancelLeaseApplication(accessToken, leaseApp);
    });
    Promise.all(promises)
      .then(() => {
        dispatch(
          setLoaderModal({
            visibility: true,
            text: 'Lade Fahrzeug- und Antragsdaten...',
          }),
        );
        dispatch(fetchLeaseApps({ accessToken }))
          .then(() => {
            dispatch(clearCarData());
            ls.remove('carData');
            ls.remove('ar');
            ls.remove('ps');
            ls.remove('bo');
            ls.remove('tr');
            ls.remove('loa');
            ls.remove('pcms');
            ls.remove('companyName');
            ls.remove('companyZip');
            navigate(`/lease-application/${selectedUUID}/${sequence + 1}`);
          })
          .catch((e) => {
            dispatch(
              setLoaderModal({
                visibility: false,
                text: 'Lade Fahrzeug- und Antragsdaten...',
              }),
            );
            console.error(e);
          });
      })
      .catch((e) => {
        dispatch(
          setLoaderModal({
            visibility: false,
            text: 'Lade Fahrzeug- und Antragsdaten...',
          }),
        );
        console.error(e);
      });
  };

  // Format price with german price format
  const { format } = formatGermanPrice();

  return (
    <motion.div
      whileHover='active'
      whileTap='clicked'
      animate='off'
      initial='off'
      className='car-selector'
    >
      <div className='left car-selector-content'>
        <p className='content-normal dark-grey description'>
          {appLeaseDate
            ? `Angebotsdatum: ${appLeaseDate}, ${time}`
            : 'Noch Keinen Antrag angefangen'}
        </p>
        <h3 className='title'>
          {carName}
          <br />
          <span className='price'>
            {format(rate as any)} <span className='content-normal'>mtl.</span>
          </span>
        </h3>
        {color && (
          <p className='content-normal dark-grey description'>{color}</p>
        )}
        {cushion && (
          <p className='content-normal dark-grey description'>{cushion}</p>
        )}
        <h3 className='price-desktop title'>
          <span>{format(rate as any)}</span> mtl.
        </h3>
      </div>
      <div className='car-price' style={{ paddingTop: '80px' }}>
        <img src={img || CarImage} alt='vehicle' />
      </div>
      <ButtonGroup
        className='back-next'
        type='default'
        buttonOneProps={{
          type: 'submit',
          name: 'form-submit',
          className: 'button-text',
          dataTestId: 'Fahrzeug auswählen',
          children: 'Fahrzeug auswählen',
          onClick: () => handleClick(uuid),
        }}
      />
    </motion.div>
  );
};

export default CarSelection;
