import React from 'react';
import { useSelector } from 'react-redux';
import { getDocument } from '../../../api/instant-lease-api';
import { setNotification } from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { Document } from '../../../types/instant-lease-api';
import { getText } from '../../../utils/getter';
import { ReactComponent as CheckMarkIcon } from '../../b2b/components/icons/check.svg';
import { ReactComponent as CloseIcon } from '../../b2b/components/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../b2b/components/icons/delete.svg';
import { ReactComponent as DeleteIconWhite } from '../../b2b/components/icons/delete_inv.svg';
import { ReactComponent as UploadIcon } from '../../b2b/components/icons/upload.svg';
import './upload-input.css';

interface UploadInputProps {
  className?: string;
  documentType?: string;
  document?: Document;
  fileName?: string;
  onChange?: any;
  onDelete?: any;
  uploadProgress?: number;
  error?: string;
  isLoading?: boolean;
}

const UploadInput: React.FC<UploadInputProps> = ({
  className,
  documentType,
  document,
  fileName = 'document',
  onChange,
  onDelete,
  uploadProgress = 100,
  isLoading = false,
  error,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Dispatch hook
  const dispatch = useAppDispatch();

  const shortenFileName = (filename: String): String => {
    if (filename.length <= 40) {
      return filename;
    }
    return (
      filename.substring(0, 18) +
      '...' +
      filename.substring(filename.length - 19, filename.length)
    );
  };

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Check if document exists
  const isDocumentExist = (docType: string): Document | undefined =>
    activeApplication?.registered_documents?.find(
      (doc) => doc.document_type === docType,
    );
  // Download the requested from API
  const downloadFile = (docType: Document | undefined) => {
    if (docType) {
      const { mime_type, token, file_name } = docType;
      getDocument(accessToken, activeApplication?.uuid || '', token)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          // link.download = file_name;
          link.setAttribute('download', file_name);
          link.click();
          window.open(blob, '_blank');
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  return (
    <div className={`upload-container ${className || ''}`}>
      <div
        className={`${document ? 'upload-box uploaded' : ''} ${
          uploadProgress !== 0 && uploadProgress !== 100 ? '' : 'upload-box'
        } mobile-hidden`}
      >
        {uploadProgress !== 0 && uploadProgress !== 100 && (
          <div className='progress-upload'>
            <div className='back content-bold'>
              Uploading: {uploadProgress}%
            </div>
            <div
              className='front content-bold'
              style={{ clipPath: `inset(0 0 0 ${uploadProgress}%)` }}
            >
              Uploading: {uploadProgress}%
            </div>
          </div>
        )}

        {!document && (uploadProgress === 0 || uploadProgress >= 100) && (
          <>
            <div className='upload-box-details'>
              <div
                data-testid='upload-box-icon'
                className='upload-box-details-upload-icon-box'
              >
                {error ? (
                  <CloseIcon
                    width='20'
                    height='20'
                    color='#ef476f'
                    className='upload-box-details-upload-icon close-icon'
                    data-testid='uploaded-icon'
                  />
                ) : (
                  <CheckMarkIcon
                    width='32'
                    height='32'
                    className='upload-box-details-upload-icon'
                    data-testid='uploaded-icon'
                  />
                )}
              </div>

              <div data-testid='file_name' className='upload-box-details-box'>
                <span className='upload-header bg content-bold'>
                  {fileName}
                </span>
                <span className='small-text dark-grey bg2'>
                  JPG, PNG, PDF, max. 5 MB, höchstens 6 Monate alt
                </span>
              </div>
            </div>
            <div className='upload-box-actions'>
              <UploadIcon
                className='upload-box-actions-plus-icon'
                data-testid='upload-icon'
              />
              <label htmlFor={`${documentType?.toLocaleLowerCase()}-input`}>
                <input
                  id={`${documentType?.toLocaleLowerCase()}-input`}
                  data-testid={`${documentType?.toLocaleLowerCase()}-input`}
                  onChange={onChange}
                  type='file'
                  name={documentType?.toLocaleLowerCase()}
                />
              </label>
              <span className='upload-box-actions-text content-bold'>
                Datei hochladen
              </span>
            </div>
          </>
        )}
        {document && (
          <>
            <div className='upload-box-details'>
              <div className='upload-box-details-check-mark-icon-box'>
                <CheckMarkIcon
                  width='32'
                  height='32'
                  className='upload-box-details-check-mark-icon'
                  data-testid='uploaded-icon'
                />
              </div>
              <div className='upload-filename' data-testid='file_name'>
                <span
                  className='upload-box-file-text content-bold'
                  role='presentation'
                  onClick={() =>
                    downloadFile(isDocumentExist(document.document_type))
                  }
                >
                  {shortenFileName(document?.file_name)}
                </span>
              </div>
            </div>
            <div onClick={onDelete} className='upload-box-actions'>
              <DeleteIcon
                data-testid='delete-icon'
                className='upload-box-details-delete-icon'
              />
              <DeleteIconWhite
                data-testid='delete-icon'
                className='upload-box-details-delete-icon-white'
              />
              <span className='upload-box-actions-text delete content-bold'>
                Datei entfernen
              </span>
            </div>
          </>
        )}
      </div>

      <div
        className={`mobile-only ${document ? 'upload-box uploaded' : ''} ${
          uploadProgress !== 0 && uploadProgress !== 100
            ? ''
            : 'upload-box-container-progress'
        }`}
      >
        {uploadProgress !== 0 && uploadProgress !== 100 && (
          <div className='progress-upload'>
            <div className='back content-bold'>
              Uploading: {uploadProgress}%
            </div>
            <div
              className='front content-bold'
              style={{ clipPath: `inset(0 0 0 ${uploadProgress}%)` }}
            >
              Uploading: {uploadProgress}%
            </div>
          </div>
        )}

        {!document && (uploadProgress === 0 || uploadProgress >= 100) && (
          <>
            <div className='upload-box-actions-mobile'>
              <UploadIcon
                className='upload-box-actions-plus-icon-mobile'
                data-testid='upload-icon'
              />
              <label htmlFor={`${documentType?.toLocaleLowerCase()}-input`}>
                <input
                  id={`${documentType?.toLocaleLowerCase()}-input`}
                  data-testid={`${documentType?.toLocaleLowerCase()}-input`}
                  onChange={onChange}
                  type='file'
                  name={documentType?.toLocaleLowerCase()}
                />
              </label>
              <span className='upload-box-actions-text content-bold'>
                Datei hochladen
              </span>
            </div>
            <div className='upload-box-details-mobile'>
              <div
                data-testid='file_name'
                className='upload-box-details-box-mobile'
              >
                <span className='upload-header bg content-bold'>
                  {fileName}
                </span>
                <span className='content-normal dark-grey'>
                  JPG, PNG, PDF, max. 5 MB, höchstens 6 Monate alt
                </span>
              </div>
            </div>
          </>
        )}

        {document && (
          <>
            <div className='upload-box-details'>
              <div className='upload-box-details-check-mark-icon-box'>
                <CheckMarkIcon
                  width='32'
                  height='32'
                  className='upload-box-details-check-mark-icon'
                  data-testid='uploaded-icon'
                />
              </div>
              <div className='upload-filename' data-testid='file_name'>
                <span
                  className='upload-box-file-text content-bold'
                  role='presentation'
                  onClick={() =>
                    downloadFile(isDocumentExist(document.document_type))
                  }
                >
                  {shortenFileName(document?.file_name)}
                </span>
              </div>
            </div>
            <div onClick={onDelete} className='upload-box-actions'>
              <DeleteIcon
                data-testid='delete-icon'
                className='upload-box-details-delete-icon'
              />
              <DeleteIconWhite
                data-testid='delete-icon'
                className='upload-box-details-delete-icon-white'
              />
              <span className='upload-box-actions-text delete content-bold'>
                Datei entfernen
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadInput;
