import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import {
  setLoaderCreditCheckModal,
  setNotification,
} from '../../../../redux/cache-slice';
import {
  fetchLeaseApp,
  updateCustomerData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  LeaseApplication,
  LegalForm,
  Status,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { clearTimer } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import Button from '../../components/button/button';
import { ReactComponent as CreditCheck } from '../../components/icons/credit-check.svg';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CreditCheckProps {
  type: 'capital' | 'individual';
  extraInfo: string;
  getLegalform?: (
    leaseApp: LeaseApplication | null,
  ) => string | number | boolean | undefined;
}

const Creditcheck: React.FC<CreditCheckProps> = ({
  type,
  extraInfo,
  getLegalform,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  // Get initial value
  const legalForm = getLegalform ? getLegalform(activeApplication) : '';

  // Start Credit Check Fn
  const startCreditCheck = () => {
    dispatch(setLoaderCreditCheckModal({ visibility: true, animation: 'In' }));
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          data_storage: true,
          data_usage: true,
        },
        path: APIPaths.SCHUFA_AGREEMENTS,
      }),
    );

    // Submit Credit Check Request
    submitTrigger(
      accessToken,
      activeApplication?.uuid || '',
      'start_credit_check',
    ).catch(() => {
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Das tut uns leid.',
          notificationBody: getText('error_unable_to_process_data'),
        }),
      );
      errorLogging(
        new Error(
          `unable to trigger credit check to user with application id ${activeApplication?.uuid}`,
        ),
      );
    });

    // Pull Lease App Every 1000ms
    const creditCheckTimer = setInterval(async () => {
      dispatch(
        fetchLeaseApp({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
        }),
      )
        .then((response) => {
          const leaseApp = response.payload as LeaseApplication;
          if (
            leaseApp.state === Status.CREDIT_SUCCESS ||
            leaseApp.state === Status.CREDIT_PENDING ||
            leaseApp.state === Status.CREDIT_FAILED
          ) {
            clearTimer(creditCheckTimer);
            navigate(nextRoute());
          }
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to get credit check response to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }, 1000);

    // Auto clear intervel and move to next page
    setTimeout(() => {
      clearTimer(creditCheckTimer);
      navigate(nextRoute());
    }, 30000);
  };

  return (
    <Layout
      heading={getText('credit_check_title')}
      subHeading={extraInfo}
      hasFullSize={true}
    >
      {(type !== 'capital' ||
        legalForm === LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS) && (
        <div
          className='half-half-columns top-72'
          style={{ alignItems: 'start' }}
        >
          <CreditCheck className='credit-check' />
          <div>
            <h2 className='content-bold'>Deine Bonitätsprüfung</h2>
            <p className='content-normal dark-grey'>
              Um einen Leasingantrag zu bearbeiten sind wir gesetzlich
              verpflichtet, eine Bonitätsprüfung deines Unternehmens
              durchzuführen. Wir verwenden dazu unter anderem Daten von Schufa.
            </p>

            <h2 className='content-bold top-48'>Wichtiger Hinweis für dich</h2>
            <p className='content-normal dark-grey'>
              Sofern dein Vertragsabschluss eine Bonitätsprüfung erfordert,
              werden deine Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO an
              den Verband der Vereine Creditreform e.V. (Crefo) bzw. die Schufa
              Holding AG übermittelt. Eine Übermittlung auf Basis von Art. 6
              Abs. 1 lit. f DSGVO erfolgt nur, wenn dies zur Wahrung
              berechtigter Interessen der ALD notwendig ist und deine Interessen
              oder Grundrechte und -freiheiten nicht überwiegen. Alle
              Informationen zur Datenverarbeitung durch die Schufa{' '}
              <a
                href='https://www.meineschufa.de'
                target='_blank'
                rel='noopener noreferrer'
              >
                findest du auf www.meineschufa.de
              </a>
              {''}.
            </p>

            <Button
              type='button'
              onClick={startCreditCheck}
              style={{ width: '100%', marginTop: '48px' }}
            >
              <span className='content-bold'>Bonitätsprüfung starten</span>
            </Button>
          </div>
        </div>
      )}

      {type === 'capital' && (
        <ButtonGroup
          className='back-next medium'
          type='back-next'
          buttonOneProps={{
            type: 'submit',
            onClick: startCreditCheck,
            dataTestId: 'next',
            children: 'Bonitätsprüfung starten',
          }}
        />
      )}
    </Layout>
  );
};

export default Creditcheck;
