import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteSavedUserOffer,
  getSavedUserOffer,
} from '../api/instant-lease-api';
import { isAuthenticated } from '../redux/auth/authUtils';
import { setNotification } from '../redux/cache-slice';
import {
  createLeaseApp,
  fetchLeaseApps,
} from '../redux/leaseApp/lease-app-slice';
import store, { RootState, useAppDispatch } from '../redux/store';
import { Offer, Partner } from '../types/instant-lease-api';
import { getText } from '../utils/getter';

interface SavedUserOffer {
  data: {
    message: string;
    timestamp: string;
    status: string;
    user_details: Offer[];
  };
}

const useOfferCreation = (): void => {
  // Auth State
  const auth = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Get active lease application state from store
    const activeApplication = store.getState().leaseApp?.activeApplication;

    // Fetch lease applications
    const getLeaseApplications = () => {
      return new Promise<string>((resolve, reject) => {
        return dispatch(fetchLeaseApps({ accessToken: auth.accessToken }))
          .then(() => resolve('Getting Leaseing Application Fulfilled'))
          .catch(() => reject());
      });
    };

    // Create new lease application
    const createNewLeasingApplication = (offer: Offer) => {
      return new Promise<string>((resolve, reject) => {
        const defaultPartner = offer?.partner ? offer?.partner : Partner.ALD;
        if (offer?.offerId && offer?.offerId !== 'null' && defaultPartner) {
          return dispatch(
            createLeaseApp({
              accessToken: auth.accessToken,
              partner: defaultPartner,
              offerId: offer?.offerId,
              pricingId: offer?.pricingId,
              publicationId: offer?.publicationId,
              type: offer?.type,
              vin: offer?.vin,
              loyaltyId: offer?.loyaltyId,
              loyaltyType: offer?.loyaltyType,
              marketingData: offer?.marketingData,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              dispatch(fetchLeaseApps({ accessToken: auth.accessToken }));
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              });
            })
            .catch((error) => {
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              }).then(() => {
                dispatch(
                  setNotification({
                    notificationVisibility: true,
                    notificationHasError: true,
                    notificationTitle: 'Das tut uns leid.',
                    notificationBody: getText(
                      'cannot_create_lease_application',
                    ),
                  }),
                );
                return reject(`error creating leasing application ${error}`);
              });
            });
        }
        return false;
      });
    };

    // If user Authenticated
    if (isAuthenticated(auth)) {
      getSavedUserOffer({
        accessToken: auth.accessToken,
        email: auth?.email,
      })
        .then(({ data }: SavedUserOffer) => {
          if (data?.user_details.length > 0 && auth.emailVerified === true) {
            createNewLeasingApplication(data?.user_details[0])
              .then(() => {
                deleteSavedUserOffer({
                  accessToken: auth.accessToken,
                  email: auth?.email,
                  offerId: data?.user_details[0].offerId,
                });
              })
              .catch(() =>
                dispatch(
                  setNotification({
                    notificationVisibility: true,
                    notificationHasError: true,
                    notificationTitle: 'Das tut uns leid.',
                    notificationBody: getText(
                      'cannot_create_lease_application',
                    ),
                  }),
                ),
              );
          }
        })
        .catch(() => console.log('error'));

      if (!activeApplication) {
        getLeaseApplications().catch(() =>
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('cannot_create_lease_application'),
            }),
          ),
        );
      }
    }
  }, [auth, dispatch]);
};

export default useOfferCreation;