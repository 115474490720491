import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';
import { setCurrentView } from '../../../../redux/sequence/sequence-slice';
import { Sequence, Step } from '../../../../redux/sequence/types';
import { isAdmin } from '../../../../utils/helper';
import './progress-bar.css';

interface ProgressBarProps extends React.HTMLProps<HTMLDivElement> {
  sequence: Sequence;
  steps?: Step[];
}

const ProgressBar: React.FC<ProgressBarProps> = ({ sequence, steps }) => {
  // Dispatch hook
  const dispatch = useDispatch();

  // Navigation hook
  const navigate = useNavigate();

  // Tool Tip control
  const [toolTipVisibility, setToolTipVisibility] = useState<number>(-1);

  // Step storage
  const { step, completedTillStep } = sequence;

  // Application ID
  const leaseApplicationID = window.location.pathname.split('/')[2];

  useEffect(() => {
    // Get all elements with the class name 'progress-tooltip'
    const elements: any = document.getElementsByClassName('progress-tooltip');

    // Loop through the elements and change their height to match the text size
    for (let i = 0; i < elements.length; i++) {
      const height = elements[i].offsetHeight;
      if (height > 30) {
        elements[i].style['margin-top'] = '-75px';
      }
    }
  }, []);

  return (
    <div className='progress' data-testid='progress-bar'>
      <div className='progress-wrapper'>
        {steps?.map((value, index) => (
          <div
            key={index}
            className={`progress-item ${step === index ? 'active' : ''} ${
              step > index ? 'completed' : ''
            } ${completedTillStep >= index ? 'clickable' : ''}`}
            role='presentation'
            onMouseEnter={() => setToolTipVisibility(index)}
            onMouseLeave={() => setToolTipVisibility(-1)}
            onClick={() => {
              if (step >= index || config.ENV === 'local' || isAdmin()) {
                dispatch(setCurrentView({ ...sequence, step: index }));
                navigate(
                  steps[index].route(
                    leaseApplicationID || '',
                    (sequence?.sequence + 1).toString(),
                  ),
                );
              }
            }}
          >
            <p
              className='progress-tooltip small-text'
              style={{
                opacity: toolTipVisibility === index ? '100%' : '0',
              }}
            >
              {steps[index].name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
