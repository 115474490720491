import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';

export interface Cache {
  showTextKey: boolean;
  hasPassport?: boolean;
  hasNewOffer?: boolean;
  hasBeneficialOwners?: boolean;
  hasTransparencyRegister?: boolean;
  hasLetterOfAuthority?: boolean;
  errorModalVisible: boolean;
  errorModalText: string;
  notificationVisibility: boolean;
  notificationHasError: boolean;
  notificationTitle: string;
  notificationBody: string;
  loaderModalVisible: boolean;
  isSubheaderVisible: boolean;
  creditCheckModalVisibility: boolean;
  creditCheckModalAnimation: 'In' | 'Out';
  isAnimated: boolean;
  loaderModalText: string | null | undefined;
}

export const initialState: Cache = {
  showTextKey: false,
  hasPassport: ls.get('ps') === 'true',
  hasNewOffer: ls.get('oid') !== null,
  hasBeneficialOwners: ls.get('bo') === 'true',
  hasTransparencyRegister: ls.get('tr') === 'true',
  hasLetterOfAuthority: ls.get('loa') === 'true',
  errorModalVisible: false,
  errorModalText: '',
  notificationVisibility: false,
  notificationHasError: false,
  notificationTitle: '',
  notificationBody: '',
  loaderModalVisible: false,
  isSubheaderVisible: false,
  creditCheckModalVisibility: false,
  creditCheckModalAnimation: 'In',
  isAnimated: false,
  loaderModalText: '',
};

export const cacheSlice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setTextKeyVisibility: (cache, action: PayloadAction<boolean>) => {
      cache.showTextKey = action.payload;
    },
    setSubheaderVisibility: (cache, action: PayloadAction<boolean>) => {
      cache.isSubheaderVisible = action.payload;
    },
    setHasNewOffer: (cache, action: PayloadAction<boolean>) => {
      cache.hasNewOffer = action.payload;
    },
    hasPassport(cache, action: PayloadAction<boolean>) {
      cache.hasPassport = action.payload;
      ls.set('ps', action.payload ? 'true' : 'false');
    },
    hasBeneficialOwners(cache, action: PayloadAction<boolean>) {
      cache.hasBeneficialOwners = action.payload;
      ls.set('bo', action.payload ? 'true' : 'false');
    },
    hasTransparencyRegister(cache, action: PayloadAction<boolean>) {
      cache.hasTransparencyRegister = action.payload;
      ls.set('tr', action.payload ? 'true' : 'false');
    },
    hasLetterOfAuthority(cache, action: PayloadAction<boolean>) {
      cache.hasLetterOfAuthority = action.payload;
      ls.set('loa', action.payload ? 'true' : 'false');
    },
    setErrorModal: (
      cache,
      action: PayloadAction<{ visible: boolean; text: string }>,
    ) => {
      cache.errorModalVisible = action.payload.visible;
      cache.errorModalText = action.payload.text;
    },
    setNotification: (
      cache,
      action: PayloadAction<{
        notificationVisibility?: boolean;
        notificationHasError?: boolean;
        notificationTitle?: string;
        notificationBody?: string;
      }>,
    ) => {
      cache.notificationVisibility =
        action.payload.notificationVisibility || false;
      cache.notificationHasError = action.payload.notificationHasError || false;
      cache.notificationTitle = action.payload.notificationTitle || '';
      cache.notificationBody = action.payload.notificationBody || '';
    },
    setIsAnimated: (cache, action: PayloadAction<boolean>) => {
      cache.isAnimated = action.payload;
    },
    setLoaderModal: (
      cache,
      action: PayloadAction<{
        visibility: boolean;
        text?: string | undefined | null;
      }>,
    ) => {
      cache.loaderModalVisible = action.payload.visibility;
      cache.loaderModalText = action.payload.text;
    },
    setLoaderCreditCheckModal: (
      cache,
      action: PayloadAction<{
        visibility: boolean;
        animation: 'In' | 'Out';
      }>,
    ) => {
      cache.creditCheckModalVisibility = action.payload.visibility;
      cache.creditCheckModalAnimation = action.payload.animation;
    },
  },
});

export const {
  setTextKeyVisibility,
  hasPassport,
  setHasNewOffer,
  hasBeneficialOwners,
  hasTransparencyRegister,
  hasLetterOfAuthority,
  setErrorModal,
  setLoaderModal,
  setNotification,
  setIsAnimated,
  setLoaderCreditCheckModal,
  setSubheaderVisibility,
} = cacheSlice.actions;

export default cacheSlice.reducer;
