import { useEffect, useState } from 'react';

const useInitialLoaderHook = (): { loadingAnimationVisibility: boolean } => {
  // Start with loading animation once application load to disable flickering
  const [loadingAnimationVisibility, setLoadingAnimationVisibility] =
    useState<boolean>(true);

  // Init loading animation
  useEffect(() => {
    setLoadingAnimationVisibility(true);

    setTimeout(() => {
      setLoadingAnimationVisibility(false);
    }, 5000);
  }, []);

  return { loadingAnimationVisibility };
};

export default useInitialLoaderHook;
