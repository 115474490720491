export const routes = {
  home: '/home',
  logout: '/logout',
  login: '/login',
  leaseApplication: {
    root: (leaseAppId: string, phase: string): string =>
      `/lease-application/${leaseAppId || ':leaseAppId'}/${phase}`,
    disclaimer: {
      root: (leaseAppId: string, phase: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/${phase}/disclaimer`,
    },
    dataProtection: {
      root: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/data-protection`,
    },
    overview: {
      root: (leaseAppId: string, phase: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/${phase}`,
    },
    companyProfile: {
      companyInfo: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/company-info`,
      companySearchResult: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/company-search-result`,
      companyAddress: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/company-address`,
      companyContacts: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/company-contacts`,
      companyData: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/company-data`,
      confirm: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/company-profile/confirm`,
    },
    kyc: {
      hasBeneficialOwners: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/has-beneficial-owners`,
      beneficialOwners: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/beneficial-owners`,
      shareholders: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/shareholders`,
      partners: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/partner`,
      hasPassport: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/identity-choice`,
      ownerData: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/owner-data`,
      authorizedRepresentatives: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/authorized-representatives`,
      documentUpload: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/documents`,
      transparencyRegister: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/transparency-register`,
      selectSigner: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/select-signer`,
      signatoriesEmails: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/signatories-emails`,
      commercialRegister: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/commercial-register`,
      transparencyRegisterUpload: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/transparency-register-upload`,
      confirm: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/kyc/confirm`,
    },
    creditCheck: {
      creditCheckStart: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/credit-check/start`,
      creditCheckStatus: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/credit-check/status`,
      creditCheckSuccess: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/credit-check/success`,
    },
    flex: {
      flexFinal: (leaseAppId: string, phase: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/${phase}/flex-final`,
    },
    identityCheck: {
      reviewContract: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/review-contract`,
      idNow: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/idnow-start`,
      postidentStart: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/postident-start`,
      postidentCoupon: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/postident-coupon`,
      signersListAGB: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/agb`,
      signersList: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check/signers-list`,
    },
    self_disclosure: {
      personalInformation: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/personal-information`,
      workFamilyHousing: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/work-family-house`,
      incomeAndExpenses: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/income-and-expense`,
      contact: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/contact`,
      bank: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/bank`,
      uploadDocuments: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/upload-documents`,
      hasCoApplicant: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/has-co-applicant`,
      coApplicant: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/co-applicant`,
      dataSummary: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/self_disclosure/data-summary`,
    },
    creditCheckB2C: {
      creditCheckB2CStart: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/credit-check-b2c/start`,
      creditCheckB2CStatus: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/credit-check-b2c/status`,
    },
    identityCheckB2C: {
      reviewContractB2C: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check-b2c/review-contract`,
      idNowB2C: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check-b2c/idnow-start`,
      idNowResponse: (leaseAppId: string, phase: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/${phase}/identity-check-b2c/response`,
    },
  },
};

export default routes;
