import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../redux/types';
import { publicPaths } from '../../../types/instant-lease-api';
import { isAdmin } from '../../../utils/helper';

const LeaseAppConditions: React.FC = () => {
  // Store
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Store
  const { isStatusOverviewOpen, sequence } = useSelector(
    (state: RootState) => state.sequence,
  );

  // Auth State
  const auth = useSelector((state: RootState) => state.auth);

  // Router
  const route = window.location.pathname.replace('/', '');

  // Check user applications
  const hasMultipleApplications = leaseApplications?.length > 1;
  const hasSingleApplication = leaseApplications?.length === 1;

  if (hasMultipleApplications && !publicPaths.includes(route)) {
    return <Navigate to='/active-applications/overview' replace />;
  }

  if (isAdmin()) {
    switch (route.split('/')[route.split('/').length - 1]) {
      case 'home':
        return <Navigate to='/home' replace />;
      case 'idf':
        return <Navigate to='/idf' replace />;
    }
  }

  if (
    hasSingleApplication &&
    auth.emailVerified &&
    isStatusOverviewOpen &&
    !publicPaths.includes(route)
  ) {
    return (
      <Navigate
        to={`/lease-application/${leaseApplications[0].uuid}/${sequence + 1}`}
        replace
      />
    );
  }

  return <></>;
};

export default LeaseAppConditions;
