import { ReactNode } from 'react';
import store from '../redux/store';
import { Type } from '../types/instant-lease-api';
import CompanyRoutes from './company-routes';
import IndividualRoutes from './individual-routes';
import PrivateRoutes from './private-routes';

type RouteDef = {
  key: string;
  route: (leaseAppId: string, phase: string) => string;
  element: ReactNode;
};

const routeDefs: () => RouteDef[] = () => {
  const leaseApp = store.getState().leaseApp.activeApplication;
  if (leaseApp?.type === Type.B2C) {
    return PrivateRoutes;
  }
  return leaseApp?.customer?.individual ? IndividualRoutes : CompanyRoutes;
};

export default routeDefs;
