import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosProgressEvent } from 'axios';
import ls from 'localstorage-slim';
import * as InstantLeaseApi from '../../api/instant-lease-api';
import {
  Address,
  APIPaths,
  APIVersions,
  AuthorizedRepresentative,
  BankAccount,
  BeneficialOwner,
  CarData,
  ContactDetails,
  Contract,
  CrefoCompany,
  LeaseApplication,
  LegalForm,
  MainIndustry,
  MarketingData,
  Partner,
  Type,
} from '../../types/instant-lease-api';
import { getLeaseAppCurrentView } from '../../utils/sequence';
import { EDirection } from '../sequence/enums';
import { setCurrentView } from '../sequence/sequence-slice';
import { AppThunk } from '../types';
import { LeaseApplicationState } from './types';

export const initialState: LeaseApplicationState = {
  leaseApplications: [],
  activeApplication: null,
  contracts: [],
  carData: null,
  requestedPath: ls.get('requested_path') || null,
  error: null,
};

const ensureCustomer = (leaseApp: LeaseApplication) => {
  if (!leaseApp.customer)
    leaseApp.customer = {
      company: {
        contact_details: {
          emails: [],
          phone_numbers: [],
          addresses: [],
        },
      },
    };
};

const ensureCompany = (leaseApp: LeaseApplication) => {
  ensureCustomer(leaseApp);
  if (!leaseApp.customer) {
    leaseApp.customer = {
      company: {
        contact_details: {
          emails: [],
          phone_numbers: [],
          addresses: [],
        },
      },
    };
    leaseApp.customer = {
      individual: {
        contact_details: {
          emails: [],
          phone_numbers: [],
          addresses: [],
        },
      },
    };
  }
};

export const fetchLeaseApp = createAsyncThunk(
  'fetchLeaseApp',
  async ({
    accessToken,
    leaseAppId,
  }: {
    accessToken: string;
    leaseAppId: string;
  }): Promise<LeaseApplication> =>
    InstantLeaseApi.fetchLeaseApplication(accessToken, leaseAppId),
);

export const fetchCarDetails = createAsyncThunk(
  'fetchCarDetails',
  async ({
    publicationId,
    pricingId,
    offerId,
  }: {
    publicationId?: string | undefined | null;
    pricingId?: string | undefined | null;
    offerId?: string | undefined | null;
  }): Promise<CarData | void> =>
    InstantLeaseApi.fetchCarDetails({ publicationId, pricingId, offerId }),
);

export const fetchLeaseApps = createAsyncThunk(
  'fetchLeaseApps',
  async ({
    accessToken,
  }: {
    accessToken: string;
  }): Promise<LeaseApplication[]> =>
    InstantLeaseApi.fetchLeaseApplications(accessToken),
);

export const createLeaseApp = createAsyncThunk(
  'createLeaseApp',
  async ({
    accessToken,
    partner,
    offerId,
    pricingId,
    publicationId,
    type,
    vin,
    loyaltyId,
    loyaltyType,
    marketingData,
  }: {
    accessToken: string;
    partner: string;
    offerId: string;
    pricingId?: string;
    publicationId?: string;
    type?: Type;
    vin?: string;
    loyaltyId?: string;
    loyaltyType?: string;
    marketingData?: MarketingData;
  }): Promise<LeaseApplication> =>
    InstantLeaseApi.createLeaseApplication({
      accessToken,
      partner: partner.toUpperCase() as Partner,
      offerId,
      pricingId,
      publicationId,
      type,
      vin,
      loyaltyId,
      loyaltyType,
      marketingData,
    }),
);

export const updateCustomerData = createAsyncThunk(
  'updateClient',
  async ({
    accessToken,
    leaseApplicationId,
    path,
    inputValue,
    version,
  }: {
    accessToken: string;
    leaseApplicationId: string;
    path: APIPaths;
    inputValue: Record<string, unknown>;
    version?: APIVersions;
  }) =>
    InstantLeaseApi.updateClient({
      accessToken,
      leaseApplicationId,
      path,
      inputValue,
      version,
    }),
);

export const uploadCustomerDocument = createAsyncThunk(
  'updateDocument',
  async ({
    accessToken,
    leaseAppId,
    documentType,
    file,
    uploadProgress,
  }: {
    accessToken: string;
    leaseAppId: string;
    documentType: string;
    file: FormData;
    uploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined;
  }) =>
    InstantLeaseApi.uploadDocument(
      accessToken,
      leaseAppId,
      documentType,
      file,
      uploadProgress,
    ),
);

export const deleteCustomerDocument = createAsyncThunk(
  'deleteDocument',
  async ({
    accessToken,
    leaseAppId,
    documentToken,
    documentType,
  }: {
    accessToken: string;
    leaseAppId: string;
    documentToken: string;
    documentType: string;
  }) =>
    InstantLeaseApi.deleteDocument(
      accessToken,
      leaseAppId,
      documentToken,
      documentType,
    ),
);

const leaseApplicationSlice = createSlice({
  name: 'leaseApplication',
  initialState,
  reducers: {
    setRequestedPath(state, { payload: requestedPath }: PayloadAction<string>) {
      state.requestedPath = requestedPath;
      ls.set('requested_path', requestedPath);
    },
    clearRequestedPath(state) {
      state.requestedPath = null;
      ls.remove('requested_path');
    },
    setLeaseApplications(state, action: PayloadAction<LeaseApplication[]>) {
      state.leaseApplications = action.payload;
    },
    setCompanySearchResult(
      { activeApplication },
      action: PayloadAction<CrefoCompany[]>,
    ) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        activeApplication.company_search_result = action.payload;
      }
    },
    setCompanyName({ activeApplication }, action: PayloadAction<string>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.name = action.payload;
        }
      }
    },
    setAddress({ activeApplication }, action: PayloadAction<Address>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        const { street_line } = action.payload;
        const { postal_code } = action.payload;
        const { locality } = action.payload;
        if (activeApplication?.customer?.company) {
          activeApplication.customer.company.contact_details?.addresses?.push({
            street_line,
            postal_code,
            locality,
          });
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.contact_details?.addresses?.push(
            {
              street_line,
              postal_code,
              locality,
            },
          );
        }
      }
    },
    setTradeRegistry({ activeApplication }, action: PayloadAction<string>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.trade_registry_number =
            action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.trade_registry_number =
            action.payload;
        }
      }
    },
    setEstablishmentDate({ activeApplication }, action: PayloadAction<string>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.establishment_date =
            action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.establishment_date =
            action.payload;
        }
      }
    },
    setWebAddress({ activeApplication }, action: PayloadAction<string>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.website_url = action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.website_url = action.payload;
        }
      }
    },
    setLegalForm({ activeApplication }, action: PayloadAction<LegalForm>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.legal_form = action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.legal_form = action.payload;
        }
      }
    },
    setIsOwner(
      { activeApplication },
      action: PayloadAction<AuthorizedRepresentative>,
    ) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        activeApplication.customer!.individual!.owner = action.payload;
      }
    },
    setMainIndustrySector(
      { activeApplication },
      action: PayloadAction<MainIndustry>,
    ) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.main_industry = action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.main_industry = action.payload;
        }
      }
    },
    setCarPool({ activeApplication }, action: PayloadAction<number>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer!.company!.car_pool_size = action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.car_pool_size = action.payload;
        }
      }
    },
    setContactDetails(
      { activeApplication },
      action: PayloadAction<ContactDetails>,
    ) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.contact_details!.emails =
            action.payload.emails;
          activeApplication.customer.company.contact_details!.phone_numbers =
            action.payload.phone_numbers;
        }
        if (activeApplication?.customer?.individual?.contact_details) {
          activeApplication.customer.individual.contact_details.emails =
            action.payload.emails;
          activeApplication.customer.individual.contact_details.phone_numbers =
            action.payload.phone_numbers;
        }
      }
    },
    setIban({ activeApplication }, action: PayloadAction<BankAccount>) {
      if (activeApplication) {
        ensureCompany(activeApplication);
        if (activeApplication.customer?.company) {
          activeApplication.customer.company.bank_account = action.payload;
        }
        if (activeApplication?.customer?.individual) {
          activeApplication.customer.individual.bank_account = action.payload;
        }
      }
    },
    setBeneficialOwners(
      { activeApplication },
      action: PayloadAction<BeneficialOwner[] | undefined>,
    ) {
      if (activeApplication?.customer?.company) {
        activeApplication.customer.company.beneficial_owners = action.payload;
      }
    },
    setAuthorizedRepresentatives(
      { activeApplication },
      action: PayloadAction<AuthorizedRepresentative[]>,
    ) {
      if (activeApplication?.customer?.company?.authorized_representatives) {
        activeApplication.customer.company.authorized_representatives =
          action.payload;
      }
    },
    setSigners(
      { activeApplication },
      action: PayloadAction<AuthorizedRepresentative[]>,
    ) {
      if (activeApplication?.customer?.company) {
        const company = activeApplication?.customer?.company;
        company.signers = action.payload;
      }
    },
    addLeaseApplication(state, action: PayloadAction<LeaseApplication>) {
      state.leaseApplications.push(action.payload);
    },
    setActiveLeaseApp(state, action: PayloadAction<LeaseApplication>) {
      state.activeApplication = action.payload;
    },
    clearLeaseData(state) {
      state.leaseApplications = [];
      state.activeApplication = null;
      state.carData = null;
      state.requestedPath = null;
      state.error = null;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setContracts(state, action: PayloadAction<Contract[]>) {
      state.contracts = action.payload;
    },
    clearCarData(state) {
      state.carData = null;
    },
    clearActiveLeaseApplication(state) {
      state.activeApplication = null;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaseApp.fulfilled, (state, action) => {
        state.activeApplication = action.payload;
      })
      .addCase(fetchLeaseApps.fulfilled, (state, action) => {
        state.leaseApplications = action.payload;
      })
      .addCase(fetchCarDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.carData = action.payload;
        }
      })
      .addCase(createLeaseApp.fulfilled, (state, action) => {
        state.activeApplication = action.payload;
        state.leaseApplications.push(action.payload);
      })
      .addCase(updateCustomerData.fulfilled, (state, action) => {
        state.activeApplication = action.payload;
      })
      .addCase(uploadCustomerDocument.fulfilled, (state, action) => {
        state.activeApplication = action.payload;
      })
      .addCase(deleteCustomerDocument.fulfilled, (state, action) => {
        state.activeApplication = action.payload;
      })
      .addCase(fetchLeaseApp.rejected, (state) => {
        state.error = 'Error Fetching Leasing Application';
      })
      .addCase(fetchCarDetails.rejected, (state) => {
        state.error = 'Error Fetching Car Details';
      })
      .addCase(fetchLeaseApps.rejected, (state) => {
        state.error = 'Error Fetching Leasing Applications';
      })
      .addCase(createLeaseApp.rejected, (state) => {
        state.error = 'Error Creating Leasing Application';
      })
      .addCase(updateCustomerData.rejected, (state) => {
        state.error = 'Error Updating Customer Data';
      })
      .addCase(uploadCustomerDocument.rejected, (state) => {
        state.error = 'Error Uploading Customer Document';
      })
      .addCase(deleteCustomerDocument.rejected, (state) => {
        state.error = 'Error Deleting Customer Document';
      });
  },
});

export const {
  setRequestedPath,
  clearRequestedPath,
  setLeaseApplications,
  setCompanyName,
  setTradeRegistry,
  setAddress,
  setEstablishmentDate,
  setWebAddress,
  setLegalForm,
  setIsOwner,
  setMainIndustrySector,
  setCarPool,
  setContactDetails,
  setIban,
  setBeneficialOwners,
  setAuthorizedRepresentatives,
  setSigners,
  setContracts,
  addLeaseApplication,
  setActiveLeaseApp,
  clearLeaseData,
  clearCarData,
  clearActiveLeaseApplication,
  setError,
  clearError,
  setCompanySearchResult,
} = leaseApplicationSlice.actions;

export interface LeaseAppDetails {
  offerId: string;
  partner: string;
}

export const setActiveLeaseAppDetails =
  ({
    accessToken,
    leaseAppId,
    existApplication,
  }: {
    accessToken?: string;
    leaseAppId?: string;
    existApplication?: LeaseApplication;
  }): AppThunk =>
  async (dispatch) => {
    try {
      let application = existApplication;
      if (!existApplication) {
        application = await InstantLeaseApi.fetchLeaseApplication(
          accessToken || '',
          leaseAppId || '',
        );
      }
      if (application) {
        const publications = application.offer?.publication;
        const currentView = getLeaseAppCurrentView(application);
        if (publications?.pricing_id && publications.publication_id) {
          dispatch(
            fetchCarDetails({
              publicationId: publications.publication_id,
              pricingId: publications?.pricing_id,
            }),
          );
        } else {
          dispatch(clearCarData());
        }
        dispatch(
          setCurrentView({
            ...currentView,
            direction: EDirection.FORWARDS,
            error: null,
          }),
        );
        dispatch(
          setActiveLeaseApp({
            ...application,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        setError(`${e.message}: ${JSON.stringify(e.response?.data, null, 2)}`),
      );
    }
  };

export default leaseApplicationSlice.reducer;
