import React, { useEffect, useState } from 'react';
import { ReactComponent as ChangeItem } from '../icons/change-person.svg';
import { ReactComponent as DeleteItem } from '../icons/delete-person.svg';
import './collapsible.css';

interface CollapsibleProps {
  title: string;
  subtitle?: string;
  editItem?: () => void;
  removeItem?: () => void;
  isOpen?: boolean;
  children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  subtitle,
  title,
  editItem,
  removeItem,
  isOpen,
}) => {
  // Manage if collapsible is open
  const [collapsibleIsOpen, setCollapsibleIsOpen] = useState<boolean>(
    isOpen || false,
  );

  // Keep status of collapsible updated with isOpen
  useEffect(() => {
    if (isOpen !== undefined) {
      setCollapsibleIsOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <div className='collapsible-container' data-testid='collapsible-container'>
      <div
        className='collapsible-item pointer'
        role='presentation'
        data-testid='collapsible-arrow'
      >
        <div className='collapsible-title'>
          <span className='content-bold' data-testid='collapsible-title'>
            {title}
          </span>
          <span className='subtitle content-normal dark-grey '>{subtitle}</span>
        </div>
        {removeItem && (
          <div
            role='presentation'
            data-testid='remove-collapsible-item'
            className='center collapsible-action actions-button'
          >
            <div
              onClick={() => {
                setCollapsibleIsOpen(!collapsibleIsOpen);
                editItem && editItem();
              }}
            >
              <ChangeItem role='presentation' className='center-item' />
              <span className='content-bold' style={{ color: '#5386E4' }}>
                Ändern
              </span>
            </div>
            <div onClick={removeItem}>
              <DeleteItem role='presentation' className='center-item' />
              <span className='error content-bold'>entfernen</span>
            </div>
          </div>
        )}
      </div>

      <div className='border-bottom'>
        <div>{collapsibleIsOpen && <div className='p-3'>{children}</div>}</div>
      </div>
    </div>
  );
};

export default Collapsible;
