import axios, { InternalAxiosRequestConfig } from 'axios';
import { useDispatch } from 'react-redux';
import { setLoaderModal } from '../redux/cache-slice';

const useApiInterceptors = () => {
  // Dispatch hook
  const dispatch = useDispatch();

  // Request expections for showing loading screen
  const requestsExpections = (
    config: InternalAxiosRequestConfig<any>,
  ): boolean => {
    const expections = [
      'update',
      'documents',
      'document',
      'iban',
      'crefo',
      'userdetails',
      'contracts',
    ];

    if (config?.method === 'post') {
      return false;
    }

    if (expections.every((expection) => !config?.url?.includes(expection))) {
      return true;
    }

    return false;
  };

  // Axios interceptor for request
  axios.interceptors.request.use(
    (config) => {
      if (requestsExpections(config) === true) {
        dispatch(
          setLoaderModal({
            visibility: true,
            text: 'Lade Fahrzeug- und Antragsdaten...',
          }),
        );
      }
      return config;
    },
    (error) => {
      dispatch(
        setLoaderModal({
          visibility: false,
        }),
      );
      return Promise.reject(error);
    },
  );

  // Axios interceptor for response
  axios.interceptors.response.use(
    (response) => {
      setTimeout(() => {
        dispatch(
          setLoaderModal({
            visibility: false,
          }),
        );
      }, 5000);
      return response;
    },
    (error) => {
      dispatch(
        setLoaderModal({
          visibility: false,
        }),
      );
      return Promise.reject(error);
    },
  );
};
export default useApiInterceptors;
