import React, { useState } from 'react';
import {
  AuthorizedRepresentative,
  BeneficialOwner,
} from '../../../../types/instant-lease-api';
import { getAuthorityText } from '../../../../utils/getter';
import Collapsible from '../../components/collapsible/collapsible';
import ARModal from '../ar-modal/ar-modal';
import BOModal from '../bo-modal/bo-modal';
import BoSummary from '../bo-summary/bo-summary';

interface PersonProps {
  person: AuthorizedRepresentative | BeneficialOwner | any;
  title: string;
  removePerson: (ar: AuthorizedRepresentative | BeneficialOwner | any) => void;
  onPersonSubmit: (modifiedRepresentative: any) => void;
  editPersonOutside: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'BO' | 'AR';
}

const Person: React.FC<PersonProps> = ({
  person,
  title,
  removePerson,
  onPersonSubmit,
  editPersonOutside,
  type,
}) => {
  const [updatePerson, setUpdatePerson] = useState<any>();

  const [editPerson, setEditPerson] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<boolean>(false);

  const getSubTitle = () => {
    if (type === 'AR') {
      return getAuthorityText(
        person?.authorities ? person?.authorities[0] : '',
      );
    }
    if (type === 'BO') {
      return person.role;
    }

    return '';
  };

  return (
    <Collapsible
      title={`${person?.person?.first_name} ${person?.person?.last_name}`}
      subtitle={getSubTitle()}
      isOpen={expanded}
      editItem={() => {
        setUpdatePerson(person);
        editPersonOutside(true);
        setEditPerson(true);
        setExpanded(!expanded);
      }}
      removeItem={() => {
        removePerson(person);
        setExpanded(!expanded);
      }}
    >
      {type === 'AR' && (
        <ARModal
          title={title}
          onClose={() => {
            editPersonOutside(false);
            setEditPerson(false);
            setExpanded(false);
          }}
          visibility
          representative={updatePerson}
          toggleVisibility={() => setExpanded(!expanded)}
          toggleAdditionalModal={() => {
            setExpanded(!expanded);
          }}
          onSubmit={(modifiedRepresentative) => {
            onPersonSubmit(modifiedRepresentative);
            setEditPerson(false);
            setExpanded(false);
          }}
        />
      )}
      {type === 'BO' && (
        <>
          {editPerson ? (
            <BOModal
              title={title}
              data-testid='KYC-modal'
              onClose={() => {
                editPersonOutside(false);
                setEditPerson(false);
              }}
              visibility
              owner={updatePerson}
              toggleVisibility={() => setExpanded(!expanded)}
              toggleAdditionalModal={() => {
                setExpanded(!expanded);
              }}
              onSubmit={(modifiedRepresentative) => {
                onPersonSubmit(modifiedRepresentative);
                setEditPerson(false);
              }}
            />
          ) : (
            <BoSummary
              owner={person}
              edit={() => {
                editPersonOutside(true);
                setUpdatePerson(person);
                setEditPerson(true);
              }}
            />
          )}
        </>
      )}
    </Collapsible>
  );
};

export default Person;
