import ls from 'localstorage-slim';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  setIsAnimated,
  setSubheaderVisibility,
} from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { Status, Type } from '../../../types/instant-lease-api';
import { formatGermanPrice } from '../../../utils/getter';
import Modal from '../../b2b/components/modal/modal';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import CarDetails from '../car-details/car-details';
import { ReactComponent as ChevronDown } from '../icons/chevron-down-icon.svg';
import { ReactComponent as ChevronUp } from '../icons/chevron-up-icon.svg';
import PricingDetails from '../pricing-details/pricing-details';
import './sub-header.css';

const SubHeader: React.FC<{
  forceEnable?: boolean;
  showCancelButton?: boolean;
  bounceArrow?: boolean;
}> = ({ forceEnable, showCancelButton, bounceArrow }) => {
  // Lease Applications Store
  const { carData, activeApplication, leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // pathname
  const { pathname } = useLocation();

  // Cache Store
  const { isSubheaderVisible, isAnimated } = useSelector(
    (state: RootState) => state.cache,
  );

  // Scroll effect controller for header
  const [scrolledHeader, setScrolledHeader] = useState<boolean>(true);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Subheader visibility
  const [priceChangeModalVisibility, setPriceChangeModalVisibility] =
    useState<boolean>(false);

  const subHeaderRef = useRef<HTMLDivElement>(null);

  // Variables
  const isCommercial = activeApplication?.type === Type.B2C;

  const vehicleData = activeApplication?.offer?.vehicle_data;

  const leasingOptions = activeApplication?.offer?.leasing_options;

  const vehicleRate = isCommercial
    ? leasingOptions?.totals?.rate?.gross
    : leasingOptions?.totals?.rate?.net;

  // Subheader Control
  const isSubheaderEnabled =
    (!carData && !vehicleData) || activeApplication?.state === Status.CANCELLED
      ? false
      : true;

  // handle price change modal
  useEffect(() => {
    // PCMS stand for price change modal status
    const isShown = ls.get('pcms');
    if (
      activeApplication &&
      carData?.rate !== undefined &&
      vehicleRate !== undefined &&
      carData?.rate !== vehicleRate &&
      carData?.carFullName !== vehicleData?.full_name &&
      isShown !== 'true' &&
      isSubheaderEnabled
    ) {
      setPriceChangeModalVisibility(true);
    } else {
      setPriceChangeModalVisibility(false);
    }
  }, [
    carData?.rate,
    vehicleRate,
    activeApplication?.uuid,
    isSubheaderEnabled,
    leaseApplications.length,
    activeApplication,
    carData?.carFullName,
    vehicleData?.full_name,
  ]);

  useEffect(() => {
    window.onscroll = () => {
      const scrollMargin = document.documentElement.scrollTop;
      if (scrollMargin > 10) {
        setScrolledHeader(true);
      } else {
        setScrolledHeader(true);
      }
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        if (pathname.includes('/login')) {
          dispatch(setSubheaderVisibility(true));
          dispatch(setIsAnimated(false));

          setTimeout(() => {
            dispatch(setSubheaderVisibility(false));
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 2000);
        }
      },
      isAnimated ? 0 : 4001,
    );

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (isSubheaderVisible) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (!isSubheaderVisible) {
        const activeSequenceList = document.querySelectorAll(
          '.sequence-container.active',
        );

        if (activeSequenceList.length > 0) {
          const activeSequence = activeSequenceList[0];
          activeSequence.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }, 100);
  }, [isSubheaderVisible]);

  const toggleSubheader = () => {
    const subheaderElement = subHeaderRef.current;

    if (isSubheaderVisible) {
      if (subheaderElement) {
        subheaderElement.style.maxHeight = `${subheaderElement.scrollHeight}px`;
        setTimeout(() => {
          subheaderElement.style.maxHeight = '0';
        }, 10);
      }

      setTimeout(() => {
        dispatch(setSubheaderVisibility(false));
      }, 500);
    } else {
      dispatch(setSubheaderVisibility(true));

      if (subheaderElement) {
        subheaderElement.style.maxHeight = '0';
        setTimeout(() => {
          subheaderElement.style.maxHeight = `${subheaderElement.scrollHeight}px`;
        }, 10);
      }
    }
  };

  // Format price with german price format
  const { format } = formatGermanPrice();

  // If subheader disabled or not forced
  if (!forceEnable && !isSubheaderEnabled) {
    return <div />;
  }

  return (
    <>
      <div className={`subheader`}>
        <PricingDetails visibility={isSubheaderVisible} />
        {!isSubheaderVisible && (
          <ChevronDown
            className={`arrow-down ${bounceArrow ? 'bounce' : ''}`}
            onClick={toggleSubheader}
          />
        )}
      </div>
      <div
        ref={subHeaderRef}
        className={`subheader-body ${scrolledHeader ? 'active' : ''} ${
          isSubheaderVisible ? 'visible' : ''
        }`}
      >
        <div className={`${isSubheaderVisible ? 'visible' : 'hidden'}`}>
          <CarDetails showCancelButton={showCancelButton} />
          {isSubheaderVisible && (
            <ChevronUp
              className='arrow-down'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                toggleSubheader();
              }}
            />
          )}
        </div>
      </div>
      <Modal type='middle' direction='down' isOpen={priceChangeModalVisibility}>
        <h3 className='content-bold left'>Änderung der Leasingrate:</h3>
        <br />
        <p className='content-normal left'>
          Der Hersteller hat eine Preisänderung vorgenommen. Daher wurde dein
          Angebot neu berechnet und die auf der Fahrzeugkonfigurationsseite
          angegebene Leasingrate von {format(carData?.rate || 0)} wurde auf{' '}
          {format(vehicleRate || 0)} aktualisiert. Du kannst deinen
          Leasingantrag mit der aktualisierten Rate fortsetzen oder den
          Leasingantrag stornieren.
        </p>
        <ButtonGroup
          type='default'
          style={{ width: 'auto' }}
          className='top-24'
          buttonOneProps={{
            theme: 'primary',
            className: 'content-normal',
            children: 'Mit dem aktualisierten Angebot fortfahren',
            type: 'button',
            onClick: () => {
              ls.set('pcms', 'true');
              setPriceChangeModalVisibility(false);
            },
          }}
          buttonTwoProps={{
            theme: 'danger',
            className: 'content-normal',
            children: 'Leasingantrag stornieren',
            type: 'button',
            onClick: () => {
              setPriceChangeModalVisibility(false);
            },
          }}
        />
      </Modal>
    </>
  );
};

export default SubHeader;
