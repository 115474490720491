import React from 'react';
import Layout from '../../../../layout';
import ArrowList from '../../components/arrow-list/arrow-list';

interface StatusPageProps {
  heading: string;
  subHeading?: string;
  image: React.ReactNode;
  contentTitle: string;
  content?: Array<string>;
  children?: React.ReactNode;
}
const StatusPage: React.FC<StatusPageProps> = ({
  heading,
  subHeading,
  image,
  contentTitle,
  content,
  children,
}) => {
  return (
    <Layout heading={heading} subHeading={subHeading} hasFullSize={true}>
      <div className='half-half-columns grid-template'>
        <div>{image}</div>
        <div>
          <h2 className='status-title content-bold'>{contentTitle}</h2>
          {content && (
            <ArrowList className='content-normal dark-grey' list={content} />
          )}
          {children}
        </div>
      </div>
    </Layout>
  );
};

export default StatusPage;
