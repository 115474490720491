import { ActionCreatorWithPayload, unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  AuthorizedRepresentative,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import Collapsible from '../../components/collapsible/collapsible';
import { ReactComponent as AddPersonIcon } from '../../components/icons/add-person.svg';
import ARModal from '../../molecules/ar-modal/ar-modal';
import ButtonGroup from '../../molecules/button-group/button-group';
import Person from '../../molecules/person/person';

interface AuthorizedRepresentativesProps {
  title: string;
  emptyListTitle: string;
  ARModalTitle: string;
  createAction: ActionCreatorWithPayload<AuthorizedRepresentative[], string>;
  getInitialValue: (
    leaseApp: LeaseApplication | null,
  ) => AuthorizedRepresentative[] | undefined;
}

const AuthorizedRepresentatives: React.FC<AuthorizedRepresentativesProps> = ({
  title,
  emptyListTitle,
  ARModalTitle,
  getInitialValue,
  createAction,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Navigation class
  const { nextRoute } = new Navigation();

  // representatives state
  const [representatives, setRepresentatives] = useState<
    AuthorizedRepresentative[] | undefined
  >([]);

  // Get InitalValues
  useEffect(() => {
    const initialValues = getInitialValue(activeApplication);
    setRepresentatives(initialValues);
  }, [getInitialValue, activeApplication]);

  const [updateRepresentative, setUpdateRepresentative] =
    useState<AuthorizedRepresentative>();

  const [expanded, setExpanded] = useState<boolean>(false);

  const [editRepresentative, setEditRepresentative] = useState<boolean>(false);

  const [addNewPerson, setAddNewPerson] = useState<boolean>(false);

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // History hook
  const navigate = useNavigate();

  // Dispatch Hook
  const dispatch = useAppDispatch();

  // Remove representative from the list
  const removeRepresentative = (ar: AuthorizedRepresentative) => {
    setLoading(true);
    const updatedRepresentatives = representatives?.filter(
      (representative) => representative.uuid !== ar?.uuid,
    );
    setRepresentatives(updatedRepresentatives);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          authorized_representatives: {
            data: updatedRepresentatives,
          },
        },
        path: APIPaths.AUTHORIZED_REPRESENTATIVES,
      }),
    ).then(() => setLoading(false));
  };

  const disableButton = (): boolean => {
    if (addNewPerson) return true;
    if (representatives) return representatives.length < 1;
    return false;
  };

  // handling KYC modal submit
  const onARModalSubmit = (
    modifiedRepresentative: AuthorizedRepresentative,
  ) => {
    setLoading(true);
    // If representative selected so it is update
    if (editRepresentative) {
      const updatedRepresentatives = representatives?.map(
        (representative: AuthorizedRepresentative) =>
          representative.uuid === modifiedRepresentative.uuid
            ? {
                ...representative,
                person: {
                  ...representative.person,
                  first_name: modifiedRepresentative?.person?.first_name,
                  last_name: modifiedRepresentative?.person?.last_name,
                },
                authorities: modifiedRepresentative.authorities,
              }
            : representative,
      );
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            authorized_representatives: {
              data: updatedRepresentatives,
            },
          },
          path: APIPaths.AUTHORIZED_REPRESENTATIVES,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          // dispatch(createAction(representatives || []));
          setRepresentatives(updatedRepresentatives);
          setUpdateRepresentative(undefined);
          setAddNewPerson(false);
          setEditRepresentative(false);
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit authorized representative to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    } // no representative selected so it is a new representative
    else if (representatives) {
      setRepresentatives([
        ...representatives,
        {
          uuid: modifiedRepresentative.uuid,
          person: {
            uuid: modifiedRepresentative?.person?.uuid,
            first_name: modifiedRepresentative?.person?.first_name,
            last_name: modifiedRepresentative?.person?.last_name,
          },
          authorities: modifiedRepresentative.authorities,
        },
      ]);
      setAddNewPerson(false);
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            authorized_representatives: {
              data: [
                ...representatives,
                {
                  uuid: modifiedRepresentative.uuid,
                  person: {
                    uuid: modifiedRepresentative?.person?.uuid,
                    first_name: modifiedRepresentative?.person?.first_name,
                    last_name: modifiedRepresentative?.person?.last_name,
                  },
                  authorities: modifiedRepresentative.authorities,
                },
              ],
            },
          },
          path: APIPaths.AUTHORIZED_REPRESENTATIVES,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          setUpdateRepresentative(undefined);
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit authorized representative to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }
  };

  // Next page
  const next = () => {
    dispatch(createAction(representatives || []));
    navigate(nextRoute());
  };

  return (
    <Layout
      stepStatus
      data-testid='layout'
      className={`cr-layout ${
        representatives && representatives?.length >= 3 ? 'cr-margin' : ''
      }`}
      heading={
        representatives && representatives?.length > 0 ? title : emptyListTitle
      }
      subHeading='Bitte füge alle gesetzlich vertretungsberechtigen Personen hinzu.'
    >
      <h2 className='content-bold top-48'>Vertretungsberechtigte Personen</h2>
      {representatives && (
        <div className='persons-list top-24'>
          {representatives?.map((representative, index) => (
            <div
              className='collapsible'
              data-testid='person-details'
              key={index}
            >
              <Person
                person={representative}
                editPersonOutside={setEditRepresentative}
                removePerson={removeRepresentative}
                onPersonSubmit={onARModalSubmit}
                title={ARModalTitle}
                type='AR'
              />
            </div>
          ))}
        </div>
      )}

      {addNewPerson && (
        <div className='collapsible' data-testid='add-new-person'>
          <Collapsible
            isOpen
            title='Personendaten'
            removeItem={() => {
              setAddNewPerson(false);
              setUpdateRepresentative(undefined);
            }}
          >
            <ARModal
              title={ARModalTitle}
              data-testid='KYC-modal'
              visibility
              onClose={() => {
                setAddNewPerson(false);
                setUpdateRepresentative(undefined);
              }}
              representative={updateRepresentative}
              toggleVisibility={() => setExpanded(!expanded)}
              toggleAdditionalModal={() => {
                setExpanded(!expanded);
              }}
              onSubmit={onARModalSubmit}
            />
          </Collapsible>
        </div>
      )}

      {!addNewPerson && !editRepresentative && (
        <span
          role='presentation'
          data-testid='add-person'
          className='person-list-plus link-standalone'
          onClick={() => {
            setAddNewPerson(true);
            setEditRepresentative(false);
            setUpdateRepresentative(undefined);
          }}
        >
          <AddPersonIcon />
          <span className='button-text'>Person hinzufügen</span>
        </span>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'submit',
          onClick: next,
          loading,
          disabled: disableButton(),
          name: 'form-submit',
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default AuthorizedRepresentatives;
