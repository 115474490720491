import React from 'react';
import './checkbox.css';

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  checkboxPosition?: 'top' | 'middle';
  checkMarkType?: 'check' | 'round' | 'round-left';
  square?: boolean;
  error?: boolean;
  errorMessage?: string;
}

const Checkbox = React.forwardRef<any, CheckboxProps>(
  (
    {
      defaultValue,
      defaultChecked,
      disabled,
      children,
      name,
      type = 'radio',
      checkMarkType = 'check',
      checkboxPosition,
      onChange,
      checked,
      square,
      error,
      errorMessage,
    },
    ref,
  ) => {
    return (
      <label
        data-testid={`checkbox-container-${defaultValue || name || ''}`}
        className='checkbox'
      >
        <div className='checkbox-content'>
          {children}
          {errorMessage && (
            <>
              <span className='error small-text'>{errorMessage}</span>
            </>
          )}
        </div>
        <input
          data-testid={`checkbox-${name}`}
          type={type}
          name={name}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
          defaultValue={defaultValue}
          defaultChecked={defaultChecked}
          checked={checked}
        />
        <span
          data-testid='checkbox-checkmark'
          className={`checkmark ${error || errorMessage ? 'error' : ''}`}
          style={{ alignSelf: checkboxPosition === 'top' ? 'baseline' : '' }}
        />
      </label>
    );
  },
);

export default Checkbox;
