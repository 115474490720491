import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { cancelLeaseApplication } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import {
  hasTransparencyRegister,
  setNotification,
} from '../../../../redux/cache-slice';
import {
  fetchLeaseApp,
  setTradeRegistry,
  updateCustomerData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Company,
  Individual,
  LeaseApplication,
  LegalForm,
} from '../../../../types/instant-lease-api';
import {
  getBundeslands,
  getCommercialRegisterHeadlineText,
  getText,
} from '../../../../utils/getter';
import {
  hasCommercialRegisterValidation,
  hideCommercialRegisterSection,
  hideTransparenzregisterSection,
} from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import { validateDocuments } from '../../../../utils/validatetor';
import { ReactComponent as ContractCancel20 } from '../../../b2b/components/icons/contract_delete_20.svg';
import { ReactComponent as ContractCancel24 } from '../../../b2b/components/icons/contract_delete_24.svg';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';

import { EDevice } from '../../../../redux/sequence/enums';
import ArrowList from '../../components/arrow-list/arrow-list';
import Modal from '../../components/modal/modal';
import RadioContainer from '../../components/radio-container/radio-container';
import Radio from '../../components/radio/radio';
import UploadBox from '../../components/upload-box/upload-box';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CommercialRegisterProps {
  getInitialValue: (leaseApp: LeaseApplication | null) => Company | Individual;
}

const CmTrRegistry: React.FC<CommercialRegisterProps> = ({
  getInitialValue,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Lease Applications Store
  const { device } = useSelector((state: RootState) => state.sequence);

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Cache Storage
  const cache = useSelector((state: RootState) => state.cache);

  // dispatch hook
  const dispatch = useAppDispatch();

  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  // Is TR Exist
  const isTRExist = activeApplication?.registered_documents?.find(
    (document) => document.document_type === 'TRANSPARENCY_REGISTRY_RECORD',
  );

  // Answer State
  const [answerTR, setAnswerTR] = useState<boolean | undefined>(
    cache?.hasTransparencyRegister || isTRExist !== undefined,
  );

  // modal visibility
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // Handle checkbox changes
  const handleChangeCheckboxTR = (selection: boolean) => () => {
    dispatch(hasTransparencyRegister(selection));
    setAnswerTR(selection);
  };

  // Validation Schema
  const validationSchema = yup.object().shape({
    trade_registry_number: yup.string().when('any', {
      is: hasCommercialRegisterValidation(activeApplication),
      then: (schema) => schema.required(getText('validation_number')),
      otherwise: (schema) => schema.notRequired(),
    }),
    local_court: yup.string().when('any', {
      is: hasCommercialRegisterValidation(activeApplication),
      then: (schema) => schema.min(2, 'Pflichtfeld').required('Pflichtfeld'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  // React form hook
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // Get initial values
  useEffect(() => {
    const company = getInitialValue(activeApplication);
    if (company && (company.trade_registry_number || company.local_court)) {
      setValue('trade_registry_number', company?.trade_registry_number || '');
      setValue('local_court', company?.local_court || '');
      trigger();
    }
  }, [getInitialValue, activeApplication, setValue, trigger]);

  // Get text based on legal entity of the company
  const getTitle = (): string => {
    let title = '';
    if (
      !hideCommercialRegisterSection(activeApplication) &&
      !hideTransparenzregisterSection(activeApplication)
    ) {
      title = `${
        getCommercialRegisterHeadlineText(activeApplication).headline
      } und Transparenzregister`;
    } else if (hideCommercialRegisterSection(activeApplication)) {
      title = 'Transparenzregister';
    } else if (hideTransparenzregisterSection(activeApplication)) {
      title = `${
        getCommercialRegisterHeadlineText(activeApplication).headline
      } `;
    }
    return title;
  };

  // Cancel leasing application
  const cancelCurrentLeaseApp = () => {
    setLoading(true);
    cancelLeaseApplication(accessToken, activeApplication?.uuid as string)
      .then(() => {
        dispatch(
          fetchLeaseApp({
            accessToken,
            leaseAppId: activeApplication?.uuid || '',
          }),
        );
        setLoading(false);
        setModalVisibility(false);
        navigate('/');
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
      });
  };

  // Disable weiter button
  const disableButton = (): boolean =>
    (answerTR === undefined &&
      !hideTransparenzregisterSection(activeApplication)) ||
    !validateDocuments(activeApplication, cache).exist
      ? true
      : false;

  // Submit values
  const submit = ({
    trade_registry_number,
    local_court,
  }: {
    trade_registry_number?: string | undefined;
    local_court?: string | undefined;
  }) => {
    setLoading(true);
    if (trade_registry_number && local_court) {
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            trade_registry_number,
            local_court,
          },
          path: APIPaths.TRADE_REGISTRY,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          dispatch(setTradeRegistry(trade_registry_number));
          navigate(nextRoute());
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit trade registers data to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    } else {
      setLoading(false);
      navigate(nextRoute());
    }
  };

  return (
    <Layout heading={getTitle()} stepStatus>
      {!hideCommercialRegisterSection(activeApplication) && (
        <FormLayout
          sectionTitle={
            getCommercialRegisterHeadlineText(activeApplication).headline
          }
        >
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <InputField
                label={
                  getCommercialRegisterHeadlineText(activeApplication)
                    .tradeRegistrytext
                }
                extraLabel={
                  !hasCommercialRegisterValidation(activeApplication)
                    ? ' (optional)'
                    : ''
                }
                {...register('trade_registry_number')}
                error={errors.trade_registry_number?.message}
              />
              {activeApplication?.customer?.company?.legal_form ===
              LegalForm.STIFTUNG ? (
                <SelectBox
                  initialValue={watch('local_court')}
                  setValueCallBack={(value) => {
                    setValue('local_court', value, { shouldValidate: true });
                    trigger();
                  }}
                  label={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  placeholder={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  name='local_court'
                  options={getBundeslands}
                  hasError={!!errors.local_court?.message}
                  errorMessage={errors.local_court?.message}
                />
              ) : (
                <InputField
                  label={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  extraLabel={
                    !hasCommercialRegisterValidation(activeApplication)
                      ? ' (optional)'
                      : ''
                  }
                  {...register('local_court')}
                  error={errors.local_court?.message}
                />
              )}

              {hasCommercialRegisterValidation(activeApplication) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    color: '#5386e4',
                  }}
                >
                  <span
                    className='button-text top-16'
                    role='presentation'
                    onClick={() => {
                      setModalVisibility(true);
                    }}
                  >
                    Nicht im Register eingetragen
                  </span>
                </div>
              )}
            </div>
          </form>
        </FormLayout>
      )}

      {!hideTransparenzregisterSection(activeApplication) && (
        <div>
          <h2 className='content-bold top-48'>Transparenzregister</h2>
          <label className='label content-normal dark-grey top-24'>
            Ist dein Unternehmen nach §§ 20,21 GwG transparenzregisterpflichtig?
          </label>
          <div className='top-48'>
            <RadioContainer>
              <Radio
                checked={answerTR === true}
                onChange={handleChangeCheckboxTR(true)}
              >
                <p className='content-normal'>Ja</p>
              </Radio>
              <Radio
                checked={answerTR === false}
                onChange={handleChangeCheckboxTR(false)}
              >
                <p className='content-normal'>Nein</p>
              </Radio>
            </RadioContainer>
          </div>
        </div>
      )}

      {answerTR && (
        <div className='top-48'>
          <h2 className='title'>Bitte lade das folgende Dokument hoch</h2>
          <p className='content-normal dark-grey'>
            Beachte dabei bitte, dass du die verschiedenen Dokumente an der
            richtigen Stelle hochlädst, da wir den Vorgang sonst nicht
            weiterführen können.
          </p>

          <h3 className='top-40 content-bold'>Unternehmensnachweis</h3>

          <UploadBox
            fileName='Transparenzregister'
            documentType='TRANSPARENCY_REGISTRY_RECORD'
          />
        </div>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'button',
          onClick: handleSubmit(submit),
          name: 'form-submit',
          loading,
          disabled: disableButton(),
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
      <Modal
        isOpen={modalVisibility}
        onClose={() => setModalVisibility(false)}
        withExitBtn={false}
        type='middle'
        direction='down'
      >
        <h2 className='heading left'>
          Deine Firma hat keinen Registereintrag?
        </h2>
        <ArrowList
          className='content-normal dark-grey'
          list={[getText('no_register')]}
        />

        <ButtonGroup
          type='default'
          className='top-48'
          buttonOneProps={{
            type: 'button',
            theme: 'secondary',
            loading,
            className: 'back-next',
            onClick: () => setModalVisibility(false),
            children: 'Fortsetzen',
          }}
          buttonTwoProps={{
            type: 'button',
            theme: 'danger',
            loading,
            onClick: () => cancelCurrentLeaseApp(),
            children: (
              <span className='cancel-button'>
                {device === EDevice.MOBILE ? (
                  <ContractCancel20 />
                ) : (
                  <ContractCancel24 />
                )}
                Leasingantrag abbrechen
              </span>
            ),
          }}
        />
      </Modal>
    </Layout>
  );
};

export default CmTrRegistry;
