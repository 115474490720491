import { EDirection } from '../redux/sequence/enums';
import { Sequence } from '../redux/sequence/types';
import { routes } from '../routes';
import sequenceConfig from '../sequence/sequence-config';
import { LeaseApplication, Status, Type } from '../types/instant-lease-api';

interface viewSettings extends Sequence {
  route: (leaseAppId: string, phase: string) => string;
}

export const sequenceProgress = ({
  sequence,
  step,
  isStatusOverviewOpen,
  currentWindowLocation,
  completedTillStep,
  error,
  branches = 0,
}: Sequence): {
  next: () => viewSettings;
  previous: () => viewSettings;
  nextBranchRoute: () => viewSettings;
  previousBranchRoute: () => viewSettings;
} => {
  // Init
  const config = sequenceConfig();
  const sequenceStep = config[sequence].steps[step];

  const sequenceStepsLength = config[sequence].steps.length - 1;

  const currentURL =
    currentWindowLocation || window.location.pathname.split('/')[4];

  const stepURL =
    currentURL || config[sequence].steps[step].route('', '').split('/')[4];

  const isSameURL = currentURL === stepURL;

  const getRoute = () => {
    return isSameURL
      ? routes.leaseApplication.overview.root
      : config[sequence].steps[step].route;
  };

  const next = (): viewSettings => {
    const hasSameURL = isSameURL
      ? config[sequence].steps[step + 1]
      : config[sequence].steps[step];

    if (isStatusOverviewOpen) {
      return {
        sequence,
        step,
        completedTillStep,
        isStatusOverviewOpen: false,
        route: config[sequence].steps[step].route,
        branches,
        error,
        direction: EDirection.FORWARDS,
      };
    }

    if (sequenceStepsLength === step) {
      return {
        sequence: isSameURL ? sequence + 1 : sequence,
        step: isSameURL ? 0 : step,
        completedTillStep: 0,
        isStatusOverviewOpen: !!isSameURL,
        route: getRoute(),
        branches,
        error,
        direction: EDirection.FORWARDS,
      };
    }
    return {
      sequence,
      step: isSameURL ? step + 1 : step,
      completedTillStep,
      isStatusOverviewOpen,
      route: hasSameURL.route,
      branches,
      error,
      direction: EDirection.FORWARDS,
    };
  };

  const previous = (): viewSettings => {
    if (step !== 0) {
      return {
        sequence,
        step: isSameURL ? step - 1 : step,
        isStatusOverviewOpen,
        completedTillStep,
        route: isSameURL
          ? config[sequence].steps[step - 1].route
          : config[sequence].steps[step].route,
        branches,
        error,
        direction: EDirection.BACKWARDS,
      };
    }

    return {
      sequence,
      step,
      isStatusOverviewOpen,
      completedTillStep,
      route: config[sequence].steps[step].route,
      branches,
      error,
      direction: EDirection.BACKWARDS,
    };
  };

  const nextBranchRoute = (): viewSettings => {
    if (sequenceStep.branches) {
      // if we are in the last branch of current route
      if (sequenceStep.branches.length === branches) {
        return {
          sequence,
          step: step + 1,
          completedTillStep,
          isStatusOverviewOpen: false,
          route: config[sequence].steps[step + 1].route,
          branches: 0,
          error,
          direction: EDirection.FORWARDS,
        };
      }
      // else if has branches return next branch
      if (branches !== null) {
        return {
          sequence,
          step,
          completedTillStep,
          isStatusOverviewOpen: false,
          route: sequenceStep.branches[branches].route,
          branches: branches + 1,
          error,
          direction: EDirection.FORWARDS,
        };
      }

      return {
        sequence,
        step,
        completedTillStep,
        isStatusOverviewOpen: false,
        route: config[sequence].steps[step].route,
        branches: 0,
        error,
        direction: EDirection.FORWARDS,
      };
    }
    // default return next step as fallback
    return {
      sequence,
      step: step + 1,
      completedTillStep,
      isStatusOverviewOpen: false,
      route: config[sequence].steps[step + 1].route,
      error,
      direction: EDirection.FORWARDS,
    };
  };

  const previousBranchRoute = (): viewSettings => {
    if (branches === 1) {
      return {
        sequence,
        step,
        completedTillStep,
        isStatusOverviewOpen: false,
        route: config[sequence].steps[step].route,
        branches: 0,
        error,
        direction: EDirection.BACKWARDS,
      };
    }
    if (branches !== null) {
      return {
        sequence,
        step,
        completedTillStep,
        isStatusOverviewOpen: false,
        route: sequenceStep.branches![branches - 1].route,
        branches: branches - 1,
        error,
        direction: EDirection.BACKWARDS,
      };
    }
    return {
      sequence,
      step,
      completedTillStep,
      isStatusOverviewOpen: false,
      route: config[sequence].steps[step].route,
      branches: 0,
      error,
      direction: EDirection.BACKWARDS,
    };
  };

  return { next, previous, nextBranchRoute, previousBranchRoute };
};

export const getLeaseAppCurrentView = (
  app: LeaseApplication,
): {
  sequence: number;
  step: number;
  completedTillStep: number;
  isStatusOverviewOpen: boolean;
} => {
  if (
    app.state === undefined ||
    app.state === Status.CREATED ||
    app.state === Status.DATA_PENDING ||
    app.state === Status.LCA
  ) {
    return {
      sequence: 0,
      step: 0,
      completedTillStep: 0,
      isStatusOverviewOpen: true,
    };
  }
  if (
    app.state === Status.DATA_CONFIRMED ||
    app.state === Status.KYC_PENDING ||
    app.state === Status.DATA_COLLECTED
  ) {
    return {
      sequence: 1,
      step: 0,
      completedTillStep: 0,
      isStatusOverviewOpen: true,
    };
  }
  if (
    app.state === Status.KYC_CONFIRMED ||
    app.state === Status.CREDIT_INITIATED ||
    app.state === Status.CREDIT_PENDING
  ) {
    if (app.type === Type.B2C) {
      return {
        sequence: 1,
        step: 0,
        completedTillStep: 0,
        isStatusOverviewOpen: true,
      };
    }
    return {
      sequence: 2,
      step: 0,
      completedTillStep: 0,
      isStatusOverviewOpen: true,
    };
  }

  if (
    app.state === Status.CREDIT_SUCCESS ||
    app.state === Status.CONDITIONALLY_APPROVED ||
    app.state === Status.ESIGNATURE_PENDING ||
    app.state === Status.ESIGNATURE_ABORT ||
    app.state === Status.ESIGNATURE_RETRY ||
    app.state === Status.POSTIDENT_PENDING
  ) {
    if (app.type === Type.B2C) {
      return {
        sequence: 2,
        step: 0,
        completedTillStep: 0,
        isStatusOverviewOpen: true,
      };
    }
    return {
      sequence: 3,
      step: 0,
      completedTillStep: 0,
      isStatusOverviewOpen: true,
    };
  }

  if (
    app.state === Status.ESIGNATURE_SUCCEEDED ||
    app.state === Status.SIGNED
  ) {
    if (app.type === Type.B2C) {
      return {
        sequence: 3,
        step: 0,
        completedTillStep: 0,
        isStatusOverviewOpen: true,
      };
    }
    return {
      sequence: 4,
      step: 0,
      completedTillStep: 0,
      isStatusOverviewOpen: true,
    };
  }

  return {
    sequence: 5,
    step: 0,
    completedTillStep: 0,
    isStatusOverviewOpen: true,
  };
};
